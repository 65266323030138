import { API } from 'app/services';
import { Typeof } from 'app/utils';

//--Constants
const TOKEN = "auth.token";

class Auth {

  //--Constants
  static Roles = {
    ADMIN: 'ROLE_ADMIN',
    ANY: 'ROLE_ANY'
  }

  /**
   * Indicates if the user is logged
   * @return {boolean} true if the user is logged otherwise false
   */
  static isLogged() {
    return Auth.user !== null;
  }

  /**
   * Loads the stored auth token.
   * @return true if a token was loaded otherwise false.
   */
  static loadToken() {

    console.debug("[Auth] Loading stored token...");

    //Token from local storage
    let token = localStorage.getItem(TOKEN);

    //Invalid token
    if(!Typeof.string(token) || token.length == 0) {

      localStorage.removeItem(TOKEN);
      token = null;

      console.warn("[Auth] > Token not found");
    } else {

      console.debug("[Auth] > Token found and loaded, ready to check");
    }

    Auth.token = token;

    return Typeof.string(token);
  }

  /**
   *
   * @param {object} user
   * @param {string|null} token
   */
  static onAuth(user, token) {

    if(user && Typeof.string(token) && token.length > 0) {

      //Function to test role
      user.hasRole = (role) => {
        return user.roles.some((_role) => _role === this.Roles.ADMIN || _role === role);
      };

      Auth.user = user;
      Auth.token = token;

      localStorage.setItem(TOKEN, token);

    } else {

      Auth.user = null;
      Auth.token = null;

      localStorage.removeItem(TOKEN);
    }

    //Update API token
    API.onTokenChange(Auth.token);
  }

  /**
   * Check the latest logged user.
   */
  static async checkToken() {

    //Get last stored token
    let token = Auth.token;

    try {

      console.debug("[Auth] Checking token...");

      let response = await API.get('users/auth', { 'token': token });

      let user = response.data;

      console.debug("[Auth] > Token is valid");
      console.debug("[Auth] > Current user", user);

      Auth.onAuth(user, token);

    } catch(error) {

      console.error("[Auth] > Token is invalid");

      Auth.onAuth(null, null);
    }
  }

  /**
   * Login with the given credentials
   * @param {string} email
   * @param {string} password
   */
  static async login(email, password) {

    console.debug(`[Auth] Login for "${email}"...`);

    try {

      //Login
      let response = await API.post('users/auth', { email, password })

      let { user, token } = response.data;

      console.debug(`[Auth] > Successfully logged with`, user);

      Auth.onAuth(user, token);

    } catch(error) {

      console.error(`[Auth] > Unable to login: `, error.message);

      Auth.onAuth(null, null);

      throw error;
    }
  }

  /**
   * Logout the current logged user.
   */
  static async logout() {

    console.debug(`[Auth] Logging out...`);

    let token = Auth.token;

    if(!token) {
      return;
    }

    try {

      API.delete('/users/auth');

      console.debug(`[Auth] > Successfully logged out`);

    } catch(error) {

      console.error(`[Auth] > Unable to logout: `, error.message);

      throw error;

    } finally {

      Auth.onAuth(null, null);
    }
  }
}

//Set default
Auth.user = null;
Auth.token = null;

export default Auth;
