import Enum from './_Enum';

let Universe = {

  ACTIVE: {
    id: 'active',
    name: 'En cours',
    color: '#8bc34a',
    voidable: true
  },

  COMING: {
    id: 'coming',
    name: 'À venir',
    color: '#ffc107',
    voidable: true
  },

  VOIDED: {
    id: 'voided',
    name: 'Interrompue',
    color: '#607d8b',
    voidable: false
  },

  EXPIRED: {
    id: 'expired',
    name: 'Expirée',
    color: '#f44336',
    voidable: false
  }
};

export default Enum(Universe, Universe.FRANCE, (id, values) => {
  return values.find(value => value.id === id);
});
