import React from 'react'
import PropTypes from 'prop-types'

import { Redirect } from 'react-router-dom';
import { PublicRoute } from 'app/routes';

import { UnauthorizedPage } from 'app/pages';

import { Auth } from 'app/services';
import { Typeof } from 'app/utils';

class ProtectedRoute extends PublicRoute {

  render() {

    // Redirect to login with reference to the desired location
    if(!Auth.isLogged()) {

      return (

        <Redirect to={{
          pathname: "/login",
          state: { from: this.props.location }
        }} />

      );
    }

    // Logged user
    let user = Auth.user;

    let roles = this.props.roles;

    if(Typeof.string(roles)) {
      roles = [ roles ];
    }

    let hasAccess = false;

    if(Typeof.array(roles)) {

      // Cleanup
      roles = roles.map((role) => role.trim());

      // Check if user has at least one required role
      hasAccess = roles.some((role) => { user.hasRole(role)});

    } else {

      //No roles required
      hasAccess = true;
    }

    // Access denied
    if(!hasAccess) {

      return (
        <UnauthorizedPage />
      );
    }

    return super.render();
  }
}

ProtectedRoute.propTypes = {
  role: PropTypes.arrayOf(PropTypes.string)
};

export default ProtectedRoute;
