import React from 'react'

import { Link } from "react-router-dom";

import { Modal, Button, Spin, Result } from 'antd';

import { Flex } from 'app/components';

import { Notifications } from 'app/utils';
import { API } from 'app/services';

class PasswordRecoveryCancelPage extends React.Component {

  constructor(props) {
    super(props);

    const urlParams = new URLSearchParams(window.location.search);
    let token = urlParams.get('token');

    //No token
    if (!token) {
      props.history.push('/login');
    }

    this.state = {
      pending: false,
      success: false,
      token: token
    };
  }

  async componentDidMount() {

    const { token } = this.state;

    this.setState({
      pending: true
    });

    let newState = {
      pending: false
    };

    try {

      let payload = {
        token: token
      }

      //Reset Password
      await API.post('/users/password-reset/cancel', payload);

      newState.success = true;

    } catch (error) {

      // Unhandled error
      Notifications.error(
        "Oops, quelque chose s'est mal passé...",
        "Une erreur est survenue lors de l'annulation de la réinitialisation de votre mot de passe",
        error.message
      )

    } finally {
      this.setState(newState);
    }
  }

  render() {

    const { success } = this.state;

    return (
      <Modal title="Réinitialisation de mot de passe" visible closable={false} centered footer={false}>

        {success ? (
          <Result
            status="success"
            title="Réinitialisation interrompue !"
            subTitle="La demande de réinitialisation de votre mot de passe à été annulée avec succès"
            extra={
              <Link to="/login">
                <Button type="primary" key={"confirmation"}>
                  Retour
                </Button>
              </Link>
            }
          />
        ) : (
          <Flex vertical>
            <Spin />
          </Flex>
        )}

      </Modal>
    )
  }
}

export default PasswordRecoveryCancelPage;
