import { notification, Collapse } from 'antd';

class Notifications {

  /**
   * Show an error notification
   * @param   {string|ReactNode} title
   * @param   {string|ReactNode} description
   * @param   {string|ReactNode} details
   * @returns {String}
   */
  static error(title, description, details) {

    notification.error({
      message: title,
      description: (
        <>

          {/* Description */}
          {description}

          {/* Details */}
          {details &&
            <Collapse bordered={false}>
              <Collapse.Panel header="Détails">
                {details}
              </Collapse.Panel>
            </Collapse>
          }
        </>
      )
    })
  }

}

export default Notifications;
