import React from 'react'
import PropTypes from 'prop-types'

import background from 'app/assets/background.jpg';

import './style.scoped.css';

class DummyPage extends React.Component {

  render() {

    let { children } = this.props;

    return (
      <div className="dummy">

        {/* Background */}
        <img className='background' src={background} />

        {children}
      </div>
    );
  }
}

export default DummyPage;
