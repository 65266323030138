import React from 'react'
import PropTypes from 'prop-types'

import { Breadcrumb as AntBreadcrumb } from 'antd'
import { Link } from 'react-router-dom'

import { Typeof, BreadcrumbHelper } from 'app/utils'

import './style.css'

class Breadcrumb extends React.Component {

  componentDidMount() {

    //Bind breadcrumb
    BreadcrumbHelper.shared.bind(this);
  }

  render() {

    let items = BreadcrumbHelper.shared.items().map(({ title, path }, index) => {

      return (
        <AntBreadcrumb.Item key={index} className="item">

          {Typeof.string(path) ? (
            <Link to={path}>
              {title}
            </Link>
          ) : (
            <span>
              {title}
            </span>
          )}

        </AntBreadcrumb.Item>
      )
    });

    return (
      <AntBreadcrumb className="breadcrumb">
        {items}
      </AntBreadcrumb>

    );
  }
}

export default Breadcrumb;
