import Enum from './_Enum';

let Universe = {

  CASE: {
    id: 'CASE',
    name: 'Valise'
  },

  RACK: {
    id: 'RACK',
    name: 'Rack de chargement'
  },

  BOTH: {
    id: 'BOTH',
    name: 'Valise & Rack'
  }
};

export default Enum(Universe, undefined, (id, values) => {
  return values.find(value => value.id === id);
});
