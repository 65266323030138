import React from 'react'

import { Link } from 'react-router-dom';

import { GenericPage } from 'app/pages';
import { Input, Button, Table, Drawer, Typography, Tooltip } from 'antd';
import { ToolOutlined } from '@ant-design/icons';

const { Paragraph } = Typography

const { Column } = Table;
const { Search } = Input;

import { Flex, Pin, Unknown, Empty, FirmwareVersion, Discrete } from 'app/components';

import { BoardForm } from 'app/forms';

import { BoardStatus, Channel } from 'app/enums';

import { BreadcrumbHelper, Compare, Arrays, Functions } from 'app/utils';
import { API } from 'app/services';

import { debounce } from 'lodash-es';
import moment from 'moment';

class BoardsPage extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      fetched: false,
      boards: [],
      search: '',
      criteria: '',
      isFormOpen: false
    };
  }

  async componentDidMount() {

    BreadcrumbHelper.shared
      .append("Cartes")
      .flush();

    const params = new Map(this.props.location.search.slice(1).split('&').map(kv => kv.split('=')))

    let search = '';

    if (params.has('search')) {
      search = decodeURIComponent(params.get('search')).trim();
    }

    try {

      //API calling 'Boards'
      let response = await API.get('/boards');
      let boards = response.data

      this.setState({
        boards: boards,
        loading: false,
        search: search,
        criteria: search
      });

    } catch(err) {
        console.error(err.message)
    }
  }

  handleSearch = (e) => {

    const { value } = e.target;

    this.setState({
      search: value
    });

    this.applySearch(value)
  }

  applySearch = debounce((value) => {

    this.setState({
      criteria: value.toLowerCase()
    });
  }, 250);

  handleClick = (board) => {
    this.props.history.push(`/boards/${board.id}`);
  }

  toggleForm = () => {

    this.setState({
        isFormOpen: !this.state.isFormOpen
    });
  }

  handleFormSuccess = (board) => {

    this.setState({
      boards: [
        ...this.state.boards,
        board
      ]
    }, this.toggleForm);
  }

  render() {

    const { loading, search, criteria, isFormOpen } = this.state;

    const all = this.state.boards;
    const visible = Arrays.filterByCriteria(all, criteria, (board) => {
      return [
        `#${board.id}`,
        board.production_date,
        board.batch_number,
        Functions.breakUp(board.ble_mac_address, 2, ':'),
        BoardStatus.parse(board.status) ?? board.status,
        board.firmware?.version,
        Channel.parse(board.firmware?.channel)?.name ?? board.firmware?.channel
      ]
    })

    return(
      <GenericPage>

        <Flex vertical space={16}>

          <Flex spaceBetween space={8}>

            <Search placeholder="Rechercher" allowClear onChange={this.handleSearch} value={search}/>

            <Button type="primary" onClick={this.toggleForm}>
              Nouveau
            </Button>

          </Flex>

          <Table bordered
            loading={loading}
            dataSource={visible}
            rowKey={board => board.id}
            onRow={(board, rowIndex) => {
              return {
                onClick: () => this.handleClick(board)
              };
            }}
            pagination={{ position: [ 'bottomCenter' ]}}
            >

            {/* BLE Mac address */}
            {this.column_macAddress(all, visible)}

            {/* Batch number */}
            {this.column_batchNumber(all, visible)}

            {/* Production date */}
            {this.column_productionDate(all, visible)}

            {/* Firmware */}
            {this.column_firmware(all, visible)}

            {/* Status */}
            {this.column_status(all, visible)}

          </Table>

          {/*Right Modal*/}
          <Drawer title="Nouvelle carte" placement="right" visible={this.state.isFormOpen} onClose={this.toggleForm} maskClosable={false}>

            {isFormOpen &&
              <BoardForm
                onSuccess={this.handleFormSuccess}
                makeRequest={(_, data) => API.post('/boards', data)}
                />
            }

          </Drawer>

        </Flex>

      </GenericPage>
    );
  }

  column_macAddress = (all, visible) => {

    let sorter = (a, b) => Compare.string(a.ble_mac_address, b.ble_mac_address);

    return (
      <Column
        title="Adresse MAC BLE"
        dataIndex="ble_mac_address"
        key="ble_mac_address"
        sorter={sorter}
        render={(ble_mac_address) => {

          const macAddress = Functions.breakUp(ble_mac_address, 2, ':');

          return (
            <Paragraph copyable={{ text: macAddress }} style={{ margin: 0 }}>
              {macAddress}
            </Paragraph>
          )
        }}
      />
    )
  }

  column_batchNumber = (all, visible) => {

    let allBatchNumbers = visible
      .map((board) => board.batch_number)
      .sort(Compare.string)
      .unique()

    let filters = allBatchNumbers.map((batchNumber) => {
      return {
        text: batchNumber,
        value: batchNumber
      }
    })

    let onFilter = (filter, board) => {
      return board.batch_number == filter;
    }

    let sorter = (a, b) => Compare.string(a.batch_number, b.batch_number);

    return (
      <Column
        title="Numéro de lot"
        dataIndex="batch_number"
        key="batch_number"
        filters={filters}
        onFilter={onFilter}
        sorter={sorter}
      />
    )
  }

  column_productionDate = (all, vislble) => {

    let sorter = (a, b) => Compare.string(a.production_date, b.production_date)

    return (
      <Column
        title="Date de production"
        dataIndex="production_date"
        key="production_date"
        sorter={sorter}
        render={(production_date) => moment(production_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}
      />
    )
  }

  column_firmware = (all, visible) => {

    let allFirmwareVersions = visible
      .map((board) => board.firmware?.version)
      .filter(Boolean)
      .unique()

    let filters = [
      {
        text: 'Aucun',
        value: -1
      },
      {
        text: 'Version',
        children: allFirmwareVersions.map((firmware) => {
          return {
            text: firmware,
            value: firmware
          }
        })
      },
      {
        text: 'Canal',
        children: Channel.values().map((channel) => {
          return {
            text: <Pin color={channel.color}>{channel.name}</Pin>,
            value: channel.id
          }
        })
      }
    ]

    let onFilter = (filter, board) => {

      const firmware = board?.firmware;

      //No firmware
      if (filter === -1) {
        return !firmware;
      }

      //Channel
      if (Channel.parse(filter)) {
        return filter === firmware?.channel;
      }

      //Version
      return filter === firmware?.version;
    };

    let sorter = (a, b) => Compare.string(a.firmware?.version, b.firmware?.version);

    return (
      <Column
        title="Micrologiciel"
        dataIndex="firmware"
        key="firmware"
        filterMode="tree"
        filters={filters}
        onFilter={onFilter}
        sorter={sorter}
        render={(firmware, board) => {

          return (
            <Flex vertical alignLeft>
              {firmware ? (
                <FirmwareVersion {...firmware} />
              ) : (
                <Empty />
              )}

              {/* Factory */}
              {board.factory_firmware &&
                <Discrete>
                  <Tooltip title="Microgiciel d'usine" placement='bottom'>
                      {board.factory_firmware}
                  </Tooltip>
                </Discrete>
              }

            </Flex>
          )
        }}
      />
    )
  }

  column_status = (all, visible) => {

    let allStatus = BoardStatus.values()

    let filters = allStatus.map((status) => {
      return {
        text: <Pin color={status.color}>{status.name}</Pin>,
        value: status.id
      }
    })

    let onFilter = (filter, board) => {
      return board.status === filter
    }

    let sorter = (a, b) => Compare.string(a.status, b.status);

    return (
      <Column
        title="Statut"
        dataIndex="status"
        key="status"
        filterMode="tree"
        filters={filters}
        onFilter={onFilter}
        sorter={sorter}
        render={(rawStatus) => {

          let status = BoardStatus.parse(rawStatus)

          if(status) {

            return (
              <Pin color={status.color}>
                {status.name}
              </Pin>
            )
          } else {

            return (
              <Unknown>
                {rawStatus}
              </Unknown>
            )
          }

        }}
      />
    )
  }
}

export default BoardsPage;
