import React from 'react'

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'

import {
  EyeOutlined,
  EyeInvisibleOutlined,
  InboxOutlined
} from '@ant-design/icons';

import { Col, Row } from 'antd';
import { Button, Form, Input, Divider, Switch, Upload, Space, Tooltip } from 'antd';

const { TextArea } = Input;
const { Dragger } = Upload;

import { Discrete, Flex } from 'app/components';

import { Typeof } from 'app/utils';
import { Notifications, Validation } from 'app/utils'

import './style.scoped.css';

class FirmwareForm extends React.Component {

  constructor(props) {

    super(props);

    const { source } = props;

    let fields = [
      { name: ['available'],       value: source?.available ?? true },
      { name: ['min_app_version'], value: source?.min_app_version },
      { name: ['max_app_version'], value: source?.max_app_version },
      { name: ['changelog'],       value: source?.changelog },
    ]

    this.state = {
      pending: false,
      fields: fields,
      showPreview: false
    };
  }

  handleSubmit = async (data) => {

    const { source, makeRequest } = this.props;

    // Sanitize payload
    if (data.min_app_version?.length == 0) {
      delete data.min_app_version
    }

    if (data.max_app_version?.length == 0) {
      delete data.max_app_version
    }

    this.setState({
      pending: true
    });

    let newState = {
      pending: false
    };

    var onComplete;

    try {

      let newData = data;

      if(!Typeof.object(source)) {

        newData = new FormData();

        if(data.file) {
          newData.append("file", data.file.file);
        }

        newData.append("data", JSON.stringify(data));
      }

      let response = await makeRequest(source, newData);

      //Notify success
      onComplete = () => {
        this.props.onSuccess(response.data, Typeof.object(this.props.source))
      }

    } catch (error) {

      try {

        // Validation
        newState.fields = Validation.parseValidationsErrors(this.state.fields, error);

      } catch (error) {

        // Unhandled error
        Notifications.error(
          "Oops, quelque chose s'est mal passé...",
          "Une erreur est survenue lors de la mise à jour des informations du client",
          error.message
        )
      }

    } finally {
      this.setState(newState, onComplete);
    }
  }

  handleFieldsChange = (_, fields) => {

    this.setState({
      fields: fields
    });
  }

  handlePreviewChange = (checked) => {
    this.setState({
      showPreview: checked
    });
  };

  render() {
    const { fields, pending, showPreview } = this.state;

    let isEditing = Typeof.object(this.props.source);

    return (
      <Form
        // id="firmware-form"
        name="firmware-form"
        fields={fields}
        layout="vertical"
        onFieldsChange={this.handleFieldsChange}
        disabled={pending}
        onFinish={this.handleSubmit}
      >

        {!isEditing &&
          <Form.Item name="file" label="Binaire" required>
            <Dragger beforeUpload={() => false} accept=".bin, application/macbinary" maxCount="1">
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>

              <Discrete>
                Cliquez ou déposez un fichier binaire dans cette zone
              </Discrete>

              <br />

              <Discrete>
                Le nom du fichier doit correspondre au format<br/>« <span className='help'>type</span>-v<span className='help'>major</span>_<span className='help'>minor</span>-<span className='help'>channel</span>.bin »
              </Discrete>

            </Dragger>
          </Form.Item>
        }

      <Divider>
        Version d'application
      </Divider>

      <Row gutter={10}>

        <Col span={12}>

          {/* Channel */}
          <Form.Item name="min_app_version" label="Minimale">
            <Input placeholder="Ex: 1.0.1" />
          </Form.Item>

        </Col>

        <Col span={12}>

          {/* Channel */}
          <Form.Item name="max_app_version" label="Maximale">
            <Input placeholder="Ex: 1.0.1" />
          </Form.Item>

        </Col>

        <Col span={12}>

          {/* Available */}
          <Form.Item valuePropName="checked" name="available" label="Disponible" required>
            <Switch />
          </Form.Item>

        </Col>

        <Col span={24}>

          {/* Channel */}
          <Form.Item name="changelog" className="changelog"
            label={
              <Flex spaceBetween>
                Journal des modifications

                <Space>

                  Prévisualiser

                  <Switch
                    checked={showPreview}
                    onChange={this.handlePreviewChange}
                    checkedChildren={<EyeOutlined />}
                    unCheckedChildren={<EyeInvisibleOutlined />}
                  />
                </Space>
              </Flex>
            }
            extra = {
              <Flex alignRight>
                <a href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet" target="_blank">Markdown Cheatsheet</a>
              </Flex>
            }
          >

            {/* Preview */}
            {showPreview ? (
              <div className="changelog-preview">
                <ReactMarkdown remarkPlugins={[remarkGfm]}>{fields.find((field) => field.name == "changelog")?.value}</ReactMarkdown>
              </div>
            ) : (
              <TextArea style = {{ minHeight: '150px', display: showPreview ? 'none' : 'block' }}  />
            )}

          </Form.Item>

        </Col>

      </Row>

      <Button form="firmware-form" type="primary" htmlType="submit">
        Valider
      </Button>

    </Form>
    );
  }
}

export default FirmwareForm;
