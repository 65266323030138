import React from 'react'
import PropTypes from 'prop-types'

import { Card, Button, Statistic, Drawer, Tooltip, Tag } from 'antd';

import {
  EditOutlined,
  TagOutlined
} from '@ant-design/icons';

import { Flex, Unknown, Pin, Empty } from 'app/components';

import { SimulatorForm } from 'app/forms';

import { Conditioning, Channel } from 'app/enums';

import { API } from 'app/services';

class CardGeneral extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      isFormOpen: false
    };
  }

  toggleForm = () => {

    this.setState({
      isFormOpen: !this.state.isFormOpen
    });
  }

  handleFormSuccess = (simulator) => {

    const { onSimulatorUpdate } = this.props;
    onSimulatorUpdate(simulator, this.toggleForm);
  }

  render() {

    const { simulator } = this.props;
    const { isFormOpen } = this.state;

    let conditioning = Conditioning.parse(simulator.conditioning);
    let channel = Channel.parse(simulator.channel);

    return (
      <Card
        title="Général"
        hoverable
        extra={
          <Tooltip title="Modifier">
            <Button key={0} type="primary" shape="circle" onClick={this.toggleForm}>
              <EditOutlined />
            </Button>
          </Tooltip>
        }
      >

        <Flex spaceBetweenH wrap>

          {/* Tag */}
          <Statistic
            title="Étiquette"
            value={" "}
            prefix={
              <>
                {simulator.tag ? (
                  <Tag color="cyan" icon={<TagOutlined />}>
                    {simulator.tag}
                  </Tag>
                ) : (
                  <Empty />
                )}
              </>
            }
          />

          {/* Channel */}
          <Statistic
            title="Canal de mise à jour"
            value={channel?.name ?? " "}
            prefix={
              <>
                {channel ? (
                  <Pin color={channel.color} />
                ) : (
                  <Unknown>
                    {simulator.channel}
                  </Unknown>
                )}
              </>
            }
          />

          <Statistic
            title="Conditionnement"
            value={conditioning?.name ?? " "}
            prefix={
              <>
                {!conditioning &&
                  <Unknown>
                    {simulator.channel}
                  </Unknown>
                }
              </>
            }
          />

        </Flex>

        {/* Device form */}
        <Drawer title="Modification du simulator" placement="right" visible={isFormOpen} onClose={this.toggleForm} maskClosable={false} >

          {isFormOpen &&
            <SimulatorForm source={simulator} onSuccess={this.handleFormSuccess} makeRequest={(simulator, data) => API.put(`/simulators/${simulator.id}`, data)} />
          }

        </Drawer>

      </Card>
    )
  }
}

export default CardGeneral;
