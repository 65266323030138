import React from 'react'

import { Timeline } from 'app/components';

import { BoardEvent, BoardLogbookTag } from 'app/enums';

class TimelineTab extends React.Component {

  constructor(props){
    super(props);
  }

  render() {

    const { board } = this.props;

    return (
      <Timeline origin={`/boards/${board.id}`} events={BoardEvent} tags={BoardLogbookTag} />
    )
  }
}

export default TimelineTab;
