import React from 'react'
import PropTypes from 'prop-types'

import './style.scoped.css';

class Empty extends React.Component {

  render() {
    return (
      <span>–</span>
    );
  }
}

export default Empty;
