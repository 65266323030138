import Enum from './_Enum';

let Universe = {

  FRANCE: {
    code: 'FR',
    name: '🇫🇷 France'
  },

  DUTCH: {
    code: 'NL',
    name: '🇳🇱 Pays-Bas'
  }
};

export default Enum(Universe, Universe.FRANCE, (id, values) => {
  return values.find(country => country.code === id);
});
