import React from 'react'
import PropTypes from 'prop-types'

import {
  PhoneOutlined,
  MailOutlined,
  StarFilled
} from '@ant-design/icons';

import { Card, List, Tooltip, Space } from 'antd';

import { Typography } from 'antd';
const { Paragraph } = Typography;

import { Empty } from 'app/components';

import { Functions } from 'app/utils';

import './style.scoped.css';

class CardPrimaryContact extends React.Component {

  handleSeeAll = () => {
    window.location.hash = 'contacts';
  }

  render() {

    const { contact } = this.props;
    const { lastname, firstname, email, phone } = contact;

    let actions = []

    // Mail action
    actions.push(
      <Tooltip title="Envoyer un courriel">
        <a href={`mailto:${email}`}>
          <MailOutlined key="mail" />
        </a>
      </Tooltip>
    )

    if (phone) {

      // Phone action
      actions.push(
        <Tooltip title="Appeler">
          <a href={`tel:${phone}`}>
            <PhoneOutlined key="call" />
          </a>
        </Tooltip>
      )
    }

    return (
      <Card
        className="primary"
        title={
          <Space>
            <StarFilled style={{ color: "gold" }} />

            {`${lastname} ${firstname}`}
          </Space>
        }
        hoverable
        actions={actions}
        extra={
          <>
            {/* See all */}
            <a onClick={this.handleSeeAll}>Voir tous les contacts</a>
          </>
        }
      >
        <List>

          {/* Email */}
          <List.Item>
            <Paragraph copyable={{ text: email }} style={{ margin: 0 }}>
              {email}
            </Paragraph>
          </List.Item>

          {/* Phone */}
          {phone ? (
            <List.Item>
              <Paragraph copyable={{ text: phone }} style={{ margin: 0 }}>
                {Functions.breakUp(contact.phone, 2, '·')}
              </Paragraph>
            </List.Item>
          ) : (
            <Empty />
          )}

        </List>

      </Card>
    );
  }
}

export default CardPrimaryContact;
