import Enum from './_Enum';

let Universe = {

  OK: {
    id: 'OK',
    name: 'Ok',
    color: '#8bc34a'
  },

  REPAIR: {
    id: 'REPAIR',
    name: 'En maintenance',
    color: '#ffc107'
  },

  LOST: {
    id: 'LOST',
    name: 'Perdu',
    color: '#f44336'
  },

  STOLEN: {
    id: 'STOLEN',
    name: 'Volé',
    color: '#f44336'
  },

  HS: {
    id: 'HS',
    name: 'Hors service',
    color: '#1b1b1b'
  }
};

export default Enum(Universe, Universe.OK, (id, values) => {
  return values.find(value => value.id === id);
});
