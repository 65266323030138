import React from 'react'
import PropTypes from 'prop-types'

import { Button, Form, Space, Select, Switch } from 'antd';
import { Col, Row } from 'antd';

const { Option, OptGroup } = Select;

import { Pin, Flex, Discrete, FirmwareVersion, Empty } from 'app/components';

import { EquipmentKind, EquipmentStatus, BoardStatus, Channel } from 'app/enums';

import { API } from 'app/services';
import { Notifications, Validation, Functions, Arrays } from 'app/utils';

import moment from 'moment';

class EquipmentForm extends React.Component {

  constructor(props) {

    super(props);

    const { source } = props;

    let fields = [
      { name: ['kind'], value: source?.kind },
      { name: ['board_ref'], value: source?.board?.id },
      { name: ['status'], value: source?.status ?? EquipmentStatus.OK.id },
      { name: ['enabled'], value: source?.enabled ?? true }
    ]

    this.state = {
      fetching: true,
      boards: [],
      pending: false,
      fields: fields
    };
  }

  componentDidMount = async () => {

    const { source } = this.props;

    try {

      // Get available boards
      let response = await API.get('/boards', { filter: 'available' })

      let boards = response.data;

      if(source && source.board) {
        boards = [source.board, ...boards];
      }

      this.setState({
        boards: boards
      })

    } catch(error) {

      // Unhandled error
      Notifications.error(
        "Oops, quelque chose s'est mal passé...",
        "Une erreur est survenue",
        error.message
      )
    }

  }

  handleSubmit = async (data) => {

    const { source, makeRequest } = this.props;

    this.setState({
      pending: true
    });

    let newState = {
      pending: false
    };

    var onComplete;

    try {

      let response = await makeRequest(source, data);

      //Notify success
      onComplete = () => {
        this.props.onSuccess(response.data)
      }

    } catch (error) {

      try {

        // Validation
        newState.fields = Validation.parseValidationsErrors(this.state.fields, error);

      } catch (error) {

        // Unhandled error
        Notifications.error(
          "Oops, quelque chose s'est mal passé...",
          "Une erreur est survenue lors de la mise à jour des informations de l'équipement",
          error.message
        )
      }

    } finally {
      this.setState(newState, onComplete);
    }
  }

  handleFieldsChange = (_, fields) => {

    const { source } = this.props;

    //Add kind
    if(source) {
      fields = [{
        name: ['kind'],
        value: source.kind
      }, ...fields]
    }

    this.setState({
      fields: fields
    });
  }

  render() {

    const { source } = this.props;
    const { fields, boards, pending } = this.state;

    const kind = EquipmentKind.parse(fields[0].value);

    let kindsWithoutCategory = [];
    let kindsByCategory = {};

    EquipmentKind.values().forEach(kind => {

      if(kind.category) {

        let kinds = kindsByCategory[kind.category] ?? [];
        kinds.push(kind);
        kindsByCategory[kind.category] = kinds;

      } else {
        kindsWithoutCategory.push(kind);
      }

    });

    let renderKind = (kind) => {

      return (
        <Option key={kind.id} value={kind.id}>
          {kind.name}
        </Option>
      )
    }

    return (
      <Form
        name="simulator-form"
        fields={fields}
        layout="vertical"
        onFieldsChange={this.handleFieldsChange}
        disabled={pending}
        onFinish={this.handleSubmit}
      >

        {/* Kind */}
        {!source &&
          <Form.Item name="kind" label="Type" required>
            <Select placeholder="Choisir le type">

              {/* Events without category */}
              {kindsWithoutCategory.map(renderKind)}

              {/* Events with category */}
              {Object.entries(kindsByCategory).map(([category, events]) => {

                return (
                  <OptGroup label={category}>
                    {events.map(renderKind)}
                  </OptGroup>
                )
              })}

            </Select>
          </Form.Item>
        }

        {/* Board */}
        {kind?.hasBoard &&
          <Form.Item name="board_ref" label="Carte éléctronique">
            <Select
              placeholder="Choisir la carte"
              showSearch
              allowClear
              filterOption={(input, option) => {

                const board = boards.find((board) => board.id === option.value);

                let result = Arrays.filterByCriteria([ board ], input, (board) => {

                  return [
                    Functions.breakUp(board.ble_mac_address, 2, ':'),
                    board.batch_number,
                    moment(board.production_date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
                    BoardStatus.parse(board.status)?.name ?? board.status,
                    board.firmware?.version,
                    Channel.parse(board.firmware?.channel)?.name ?? board.firmware?.channel
                  ]
                }).length > 0;

                return result;
              }}
            >

              {boards.map((board) => {

                let status = BoardStatus.parse(board.status);

                return (
                  <Select.Option key={board.id} value={board.id}>

                    <Flex vertical>

                      {/* BLE Mac address */}
                      {Functions.breakUp(board.ble_mac_address, 2, ':')}

                      <Discrete>

                        <Flex spaceBetweenH>

                          <Space>

                            {/* Batch number */}
                            <span>
                              {board.batch_number}
                            </span>

                            {/* Production date */}
                            <span>
                              {moment(board.production_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                            </span>

                            {/* Status */}
                            {status ? (
                              <Pin color={status.color}>
                                {status.name}
                              </Pin>
                            ) : (
                              <Unknown color="red">
                                {board.status}
                              </Unknown>
                            )}

                          </Space>

                          {board.firmware ? (
                            <FirmwareVersion {...board.firmware} />
                          ) : (
                            <Empty />
                          )}

                        </Flex>

                      </Discrete>
                    </Flex>

                  </Select.Option>
                )
              })}

            </Select>
          </Form.Item>
        }

        <Row gutter={10}>

          <Col span={16}>

            {/* Status */}
            <Form.Item name="status" label="Statut" required>
              <Select>

                {EquipmentStatus.values().map((status) => {

                  return (
                    <Select.Option key={status.id} value={status.id}>
                      <Pin color={status.color}>
                        {status.name}
                      </Pin>
                    </Select.Option>
                  )
                })}

              </Select>
            </Form.Item>

          </Col>

          <Col span={8}>

            {/* Enabled */}
            <Form.Item name="enabled" label="Disponible" valuePropName="checked" required>
              <Switch />
            </Form.Item>

          </Col>

        </Row>

        <Button form="simulator-form" type="primary" htmlType="submit">
          Valider
        </Button>

      </Form>
    );
  }
}

export default EquipmentForm;
