import React from 'react'
import PropTypes from 'prop-types'

import { DummyPage } from 'app/pages';
import { Flex, VersionInfos } from 'app/components';

import { Redirect, Link } from "react-router-dom";

import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Modal, Form, Input, Button } from 'antd';

import { Notifications, Validation } from 'app/utils';
import { Auth } from 'app/services';

import logo from 'app/assets/logo.png';

import './style.scoped.css';

class LoginPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      fields: [
        {
          name: ['email']
        },
        {
          name: ['password']
        }
      ],
      shouldRedirect: Auth.isLogged(),
      pending: false
    };
  }

  handleFinish = async (data) => {

    this.setState({
      pending: true
    });

    let newState = {
      pending: false
    };

    try {

      //Login
      await Auth.login(data.email, data.password);

      //Redirect once logged
      newState.shouldRedirect = true;

    } catch (error) {

      try {

        // Validation
        newState.fields = Validation.parseValidationsErrors(this.state.fields, error);

      } catch (error) {

        // Unhandled error
        Notifications.error(
          "Oops, quelque chose s'est mal passé...",
          "Une erreur est survenue lors de l'authentification",
          error.message
        )
      }

    } finally {
      this.setState(newState);
    }
  }

  handleChange = (_, fields) => {

    this.setState({
      fields: fields
    });
  }

  render() {

    const { fields, pending, shouldRedirect } = this.state;

    //Redirecting
    let { from } = this.props.location.state || { from: { pathname: "/" } };

    if (shouldRedirect) {
      return <Redirect to={from} />;
    }

    return (
      <Modal title="Connexion" visible closable={false} centered footer={
        <Flex centerV spaceBetweenH>

          {/* Version inforamtions */}
          <VersionInfos discrete/>

          <Button form="login" type="primary" htmlType="submit" disabled={pending}>
            Se connecter
          </Button>
        </Flex>
      }>

        <div className='logo'>
          <img src={logo} />
          <span className='subtitle'>ERP - Gestion et administration</span>
        </div>

        <Form disabled={pending} name="login" onFinish={this.handleFinish} fields={fields} onFieldsChange={this.handleChange}>

            {/* Email address */}
            <Form.Item name="email" required>
              <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Adresse e-mail" />
            </Form.Item>

            {/* Password */}
            <Form.Item name="password" required>
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder="Mot de passe"
              />
            </Form.Item>

            {/* Forgotten Password */}
            <Form.Item>

              <Link to="/password/recovery">
                Mot de passe oublié
              </Link>

            </Form.Item>

        </Form>
      </Modal>
    )
  }
}

export default LoginPage;
