import React from 'react'
import PropTypes from 'prop-types'

import './style.scoped.css';

class Discrete extends React.Component {

  render() {

    const { children } = this.props;

    return (
      <div className='discrete'>
        {children}
      </div>
    );
  }
}

export default Discrete;
