import React from 'react'
import PropTypes from 'prop-types'

import {
  TabletFilled,
  EditOutlined
} from '@ant-design/icons';

import { Card, Button, Statistic, Drawer, Tooltip } from 'antd';

import { Flex } from 'app/components';

import { SimulatorDeviceForm } from 'app/forms';

import { API } from 'app/services';

class CardDevice extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isFormOpen: false
    };
  }

  toggleForm = () => {

    this.setState({
      isFormOpen: !this.state.isFormOpen
    });
  }

  handleFormSuccess = (device) => {

    const { simulator, onSimulatorUpdate } = this.props;

    onSimulatorUpdate({
      ...simulator,
      device: device
    }, this.toggleForm);
  }

  render() {

    const { simulator } = this.props;

    const { device } = simulator;
    const { model, serial } = device;

    const { isFormOpen } = this.state;

    return (
      <Card hoverable title={
        <Flex centerV space={8}>
          <TabletFilled />
          iPad
        </Flex>
      } extra={
        <Tooltip title="Modifier">
          <Button type="primary" shape="circle" onClick={this.toggleForm}>
            <EditOutlined />
          </Button>
        </Tooltip>
      }>

        <Flex spaceBetweenH wrap>
          <Statistic title="Modèle" value={model ?? "–"} />
          <Statistic title="N° de série" value={serial ?? "–"} />
        </Flex>

        {/* Form */}
        <Drawer title="Modification de l'iPad" placement="right" visible={isFormOpen} onClose={this.toggleForm} maskClosable={false} >

          {isFormOpen &&
            <SimulatorDeviceForm source={device} onSuccess={this.handleFormSuccess} makeRequest={(device, data) => API.put(`/simulators/${simulator.id}/device`, data)} />
          }

        </Drawer>

      </Card>
    )
  }
}

export default CardDevice;
