import React from 'react'
import PropTypes from 'prop-types'

import { Space, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons';

import { Flex } from 'app/components'

import { Env } from 'app/env'

import moment from 'moment'

import './style.scoped.css';

import classnames from 'classnames';

class VersionInfos extends React.Component
{
  render() {

    let { discrete } = this.props;

    let classes = classnames('version', {
      'discrete': discrete
    });

    return (
      <Tooltip placement="bottomLeft" title={
          <Flex vertical>

            {/* Date */}
            <span className='version-date'>
              {moment(Env.BUILD_DATE, 'DD-MM-YYYY HH:mm').format('[Le] DD/MM/YYYY [à] HH:mm')}
            </span>

            {/* Hash */}
            <span className='version-hash'>
              {Env.BUILD_HASH ?? "5a5054896fe9f976aaa135f1ceec73c7785f7b8ffae0ab4fce5e96f5d611b8f1"}
            </span>
          </Flex>
        } >

        {/* Version number */}
        <Space className={classes}>
          <InfoCircleOutlined />
          Version {Env.BUILD_VERSION}
        </Space>

      </Tooltip>
    );
  }
}

export default VersionInfos;
