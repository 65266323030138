import React from 'react'

import { debounce, map } from 'lodash-es';

import moment from 'moment';

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'

import {
  StarFilled,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  FormOutlined,
  TabletFilled
} from '@ant-design/icons';

import { Space, Input, Button, Table, Drawer, Typography, Tooltip, message, Popconfirm } from 'antd';

const { Column, ColumnGroup } = Table;
const { Search } = Input;
const { Text } = Typography;

import { GenericPage } from 'app/pages'

import { Flex, Pin, Empty } from 'app/components';

import { FirmwareForm } from 'app/forms';

import { Channel, EquipmentKind } from 'app/enums';

import { API } from 'app/services';
import { BreadcrumbHelper, Compare, Arrays, Typeof } from 'app/utils';

import './style.scoped.css';

class FirmwaresPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      simulators: [],
      search: '',
      criteria: '',
      isFormOpen: false,
      updateFirmware: false
    };
  }

  async componentDidMount() {

    BreadcrumbHelper.shared
      .append("Micrologiciels")
      .flush();

    const params = new Map(this.props.location.search.slice(1).split('&').map(kv => kv.split('=')))

    let search = '';

    if (params.has('search')) {
      search = decodeURIComponent(params.get('search')).trim();
    }

    try {

      //Logout
      let response = await API.get('/firmwares');
      let firmwares = response.data;

      this.setState({
        firmwares: firmwares,
        loading: false,
        search: search,
        criteria: search
      });

    } catch {

    }
  }

  handleSearch = (e) => {

    const { value } = e.target;

    this.setState({
      search: value
    });

    this.applySearch(value)
  }

  applySearch = debounce((value) => {

    this.setState({
      criteria: value.toLowerCase()
    });
  }, 250);

  handleClick = (firmware) => {
    this.props.history.push(`/firmwares/${firmware.id}`);
  }

  toggleForm = (updateFirmware) => {
    this.setState({
      isFormOpen: !this.state.isFormOpen,
      updateFirmware: updateFirmware
    });
  }

  handleFormSuccess = (firmware, updated) => {

    const { firmwares } = this.state;

    let newState;

    if(updated) {

      let newfirmwares = firmwares.map(obj => {
        if (obj.version == firmware.version && obj.channel == firmware.channel && obj.kind == firmware.kind) {
          return firmware
        }

        return obj;
      })

      newState = {
        firmwares: newfirmwares
      };

    } else {

      newState = {
        firmwares: [
          ...firmwares,
          firmware
        ]
      };
    }

    this.setState(newState, this.toggleForm);
  }

  render() {

    const { loading, search, criteria, isFormOpen, updateFirmware } = this.state;

    const all = this.state.firmwares;
    const visible = Arrays.filterByCriteria(all, criteria, (firmware) => {

      return [
        firmware.version,
        firmware.available,
        firmware.channel,
        firmware.kind,
        moment(firmware.date_create, 'YYYY-MM-DD').format('DD/MM/YYYY')
      ]
    })

    return (
      <GenericPage>

        <Flex vertical space={16} className="firmwares">

          <Flex spaceBetween space={8}>

            <Search placeholder="Rechercher" allowClear onChange={this.handleSearch} value={search} />

            <Button type="primary" onClick={() => this.toggleForm(false)}>
              Téléverser
            </Button>

          </Flex>

          <Table bordered
            loading={loading}
            dataSource={visible}
            rowKey={firmware => `${firmware.kind}-${firmware.version}-${firmware.channel}`}
            rowClassName={(firmware, index) => {
              return firmware.available ? '' : 'unavailable'
            }}
            pagination={{ position: ['bottomCenter'] }}
            expandable={{
              expandedRowRender: (firmware) => (
                <ReactMarkdown remarkPlugins={[remarkGfm]}>{firmware.changelog}</ReactMarkdown>
              ),
              rowExpandable: (firmware) => firmware.changelog,
            }}
          >
            {/* Version */}
            {this.column_Version(all, visible)}

            {/* Canal */}
            {this.column_Kind(all, visible)}

            {/* Canal */}
            {this.column_Canal(all, visible)}

            {/*  */}
            <ColumnGroup title={
              <span>
                <TabletFilled /> Version d'application
              </span>
            }>

              {/* Min app version */}
              {this.column_minAppVersion(all, visible)}

              {/* Max app version */}
              {this.column_maxAppVersion(all, visible)}

            </ColumnGroup>

            {/* Date_create */}
            {this.column_Date(all, visible)}

            {/* Available */}
            {this.column_Available(all, visible)}

            {/* Delete */}
            {this.column_Action(all, visible)}

          </Table>

          {/* Modal */}
          <Drawer title={Typeof.object(updateFirmware) ? "Modifier micrologiciel" : "Nouveau micrologiciel"} placement="right" visible={isFormOpen} onClose={() => this.toggleForm(false)} maskClosable={false} >

            {isFormOpen &&
              <FirmwareForm
                source={updateFirmware}
                onSuccess={this.handleFormSuccess}
                makeRequest={async (source, data) => {
                  if(Typeof.object(source)){
                    return API.put(`/firmwares/${source.kind}/${source.version}-${source.channel}`, data)
                  } else {
                    return await API.post(`/firmwares`, data)
                  }
                }}
              />
            }

          </Drawer>

        </Flex>

      </GenericPage>
    );
  }

  // Columns

  column_Version = (all, visible) => {

    let sorter = (a, b) => Compare.version(a.version, b.version);

    return (
      <Column
        title="Numéro de version"
        dataIndex="version"
        key="version"
        defaultSortOrder="descend"
        sorter={sorter}
      />
    )
  }

    // Columns

  column_Kind = (all, visible) => {

    let allKinds = visible
    .map((equipment) => EquipmentKind.parse(equipment.kind))
    .filter(Boolean)
    .unique()

    let filters = allKinds.map((kind) => {
      return {
        text: kind.name,
        value: kind.id
      }
    })

    let onFilter = (filter, equipment) => {
      return equipment.kind === filter;
    };

    let sorter = (a, b) => Compare.string(a.kind, b.kind);

    return (
      <Column
        title="Type"
        dataIndex="kind"
        key="kind"
        filters={filters}
        onFilter={onFilter}
        sorter={sorter}
        render={(rawKind, firmware) => {

          const kind = EquipmentKind.parse(rawKind);

          const highestFirmware = all
          .filter(v => v.kind === rawKind && v.channel === firmware.channel && v.available)
          .sort((a, b) => Compare.version(a.version, b.version))
          .slice(-1)[0];

          const channel = Channel.parse(firmware.channel);

          return (
            <Flex centerV space={6}>
              {/* Name */}
              {kind?.name ?? <Unknown>{rawKind}</Unknown>}

              {/* Highest */}
              {firmware === highestFirmware &&
                <Tooltip title="Version la plus récente pour ce type d'équipement">
                  <StarFilled style={{color: channel.color}} />
                </Tooltip>
              }
            </Flex>
          )
        }}
      />
    )
  }

  column_Canal = (all, visible) => {

    // Sort function
    let sorter = (a, b) => Compare.string(a.channel, b.channel);

    let filters = Channel.values()
      .map((channel) => {

        return {
          text: (
            <Pin color={channel.color}>
              {channel.name}
            </Pin>
          ),
          value: channel.id
        }
      })

    let onFilter = (filter, firmware) => {

      if (Typeof.string(filter)) {
        return firmware.channel === filter;
      } else {
        return !Typeof.object(firmware.channel);
      }
    };

    return (
      <Column
        title="Canal"
        dataIndex="channel"
        key="channel"
        filterMode="tree"
        filters={filters}
        onFilter={onFilter}
        sorter={sorter}
        render={(_, firmware) => {

          const channel = Channel.parse(firmware.channel);

          return (
            <>
              {channel ? (
                <Pin color={channel.color}>
                  {channel.name}
                </Pin>
              ) : (
                <Unknown>
                  {simulator.channel}
                </Unknown>
              )}
            </>
          )
        }}
      />
    )
  }

  column_minAppVersion = (all, visible) => {

    let sorter = (a, b) => Compare.version(a.min_app_version, b.min_app_version)

    let allMinAppVersions = visible
    .map((firmware) => firmware.min_app_version)
    .filter(Boolean)
    .unique()
    .sort(Compare.versionInverse)

    let filters = [
      {
        text: 'Aucun',
        value: -1
      },
      ...allMinAppVersions.map((min_app_version) => {
        return {
          text: min_app_version,
          value: min_app_version
        }
      })
    ]

    let onFilter = (filter, firmware) => {

      const { min_app_version } = firmware;
      return min_app_version ? min_app_version === filter : filter === -1;
    };

    return (
      <Column
        title="Minimale"
        dataIndex="min_app_version"
        key="min_app_version"
        sorter={sorter}
        filters={filters}
        onFilter={onFilter}
        render={(min_app_version) => min_app_version ?? <Empty />}
      />
    )
  }

  column_maxAppVersion = (all, visible) => {

    let sorter = (a, b) => Compare.version(a.max_app_version, b.max_app_version);

    let allMaxAppVersions = visible
    .map((firmware) => firmware.max_app_version)
    .filter(Boolean)
    .unique()
    .sort(Compare.versionInverse)

    let filters = [
      {
        text: 'Aucun',
        value: -1
      },
      ...allMaxAppVersions.map((max_app_version) => {
        return {
          text: max_app_version,
          value: max_app_version
        }
      })
    ]

    let onFilter = (filter, firmware) => {

      const { max_app_version } = firmware;
      return max_app_version ? max_app_version === filter : filter === -1;
    };

    return (
      <Column
        title="Maximale"
        dataIndex="max_app_version"
        key="max_app_version"
        sorter={sorter}
        filters={filters}
        onFilter={onFilter}
        render={(max_app_version) => max_app_version ?? <Empty />}
      />
    )
  }

  column_Date = (all, visible) => {

    let sorter = (a, b) => Compare.string(a.date_create, b.date_create);

    return (
      <Column
        title="Date de création"
        dataIndex="date_create"
        key="date_create"
        sorter={sorter}
        render={(date_create) => moment(date_create, 'YYYY-MM-DD').format('DD/MM/YYYY')}
      />
    )
  }

  column_Available = (all, visible) => {

    let sorter = (a, b) => Compare.number(a.available, b.available);

    let allAvailables = visible
    .map((firmware) => firmware.available)
    .unique()

    let filters = [
      ...allAvailables.map((available) => {
        return {
          text: available ? <CheckOutlined style={{color: "green"}} /> : <CloseOutlined style={{color: "red"}} />,
          value: available
        }
      })
    ]

    let onFilter = (filter, firmware) => {
      if (Typeof.bool(filter)) {
        return firmware.available == filter;
      } else {
        return !Typeof.object(firmware.available);
      }
    };

    return (
      <Column
        title="Disponible"
        dataIndex="available"
        key="available"
        sorter={sorter}
        filters={filters}
        onFilter={onFilter}
        render={(available) => available ? <CheckOutlined style={{color: "green"}} /> : <CloseOutlined style={{color: "red"}} />}
      />
    )
  }

  column_Action = (all, visible) => {

    const confirmDelete = async (firmware) => {
      await API.delete(`/firmwares/${firmware.kind}/${firmware.version}-${firmware.channel}`)
      .then(() => {
        message.success(`Micrologiciel ${firmware.kind}-${firmware.version}-${firmware.channel} supprimé`);
        const { firmwares } = this.state;

        let newFirmwares = firmwares.filter((f) => f.version !== firmware.version || f.channel !== firmware.channel || f.kind !== firmware.kind);

        this.setState({
          firmwares: newFirmwares
        });

        })
        .catch((error) => {
          message.error(`Erreur ${error}`);
        });
    };

    return (
      <Column
        title="Action"
        dataIndex="action"
        key="action"
        render={(_, record) => (
          <Space size="middle">
            <Tooltip title="Modifier">
              <Button onClick={() => this.toggleForm(record)} type="primary" shape="circle" icon={<FormOutlined />} />
            </Tooltip>

            <Popconfirm
              title={`Voulez-vous vraiment supprimer la version ${record.version}`}
              onConfirm={() => confirmDelete(record)}
              okText="Oui"
              cancelText="Non"
            >
              <Tooltip title="Supprimer">
                <Button type="danger" shape="circle" icon={<DeleteOutlined />} />
              </Tooltip>
            </Popconfirm>
          </Space>
        )}
      />
    )
  }

}

export default FirmwaresPage;
