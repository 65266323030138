class BreadcrumbHelper {

  //--Singleton
  static shared = new BreadcrumbHelper();

  //--Properties
  _component = null;
  _items = []

  /**
   *
   * @param {React.Component} component
   */
  bind(component) {
    this._component = component
    this.clear();
  }

  /**
   * Returns breadcrumb items.
   */
  items() {
    return this._items;
  }

  /**
   * Resets the breadcrumb
   */
  clear() {

    this._items = [];
    return this;
  }

  /**
   * Appends a breadcrumb item with title and optional path.
   * @param {string} title the title of the item
   * @param {string} path  path of the item
   */
  append(title, path = null) {

    this._items.push({
      title: title,
      path: path
    });

    return this;
  }

  /**
   * Updates a breadcrumb item with title and optional path.
   * @param {int}    depth the depth of the item
   * @param {string} title the title of the item
   * @param {string} path  path of the item
   */
  update(depth, title, path = null) {

    this._items[depth] = {
      title: title,
      path: path
    };

    return this;
  }

  /**
   * Force update the breadcrumb ref.
   */
  flush() {

    if(this._component !== null) {
      this._component.forceUpdate();
    }

    return this;
  }
}


export default BreadcrumbHelper;
