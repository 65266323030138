import { Typeof } from 'app/utils';

/**
 * Utilities functions.
 *
 * @author Matthieu RENAULT <matthieu.renault@ob-do.com>
 */
class Functions {

  /**
   * Capitalizes the given string.
   * @param {String} string the string to capitalizes
   * @return the capitalized string
   */
  static capitalize(string) {

    if (Typeof.string(string) && string.length > 0) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return string;
  }

  static breakUp(string, partLength, separator = "-") {

    let offset = 0;

    let parts = [];

    while(offset < string.length) {

      let part = string.slice(offset, offset + partLength);
      offset += partLength;

      parts.push(part);
    }

    return parts.join(separator);
  }

  static sanitize(object, property, deleteEmpty) {

    let value = object[property]?.trim() ?? "";

    if (deleteEmpty && value.length === 0) {
      delete object[property];
    }
  }

  /**
   * Generates an equipment serial number.
   */
  static equipmentSerial(simulator, kind, identifier) {

    return `NS${simulator.toString(16).toUpperCase().padStart(4, '0')}-${kind}${identifier.toString(16).toUpperCase().padStart(2, '0')}`
  }
}

export default Functions;
