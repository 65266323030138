import Enum from './_Enum';

import { Link } from 'react-router-dom';

import {
  HistoryOutlined,
  LinkOutlined,
  CloudSyncOutlined,
  SyncOutlined
} from '@ant-design/icons';

import { Space } from 'antd';

import { Flex, Pin, Unknown, Discrete } from 'app/components';

import { EquipmentStatus, Channel } from 'app/enums';

import { API } from 'app/services';

let Universe = {

  BOARD_CHANGE: {
    type: 'BOARD_CHANGE',
    name: 'Changement de carte',
    message: (payload) => {

      const { board } = payload;

      return (
        <Space>
          {board ? (
            <>
              La carte éléctronique <Link to={`/boards/${board}`}>#{board}</Link> a été associée
            </>
          ) : (
            <>
              La carte éléctronique a été dissociée
            </>
          )}
        </Space>
      )
    },
    icon: <LinkOutlined />
  },

  STATUS_CHANGE: {
    type: 'STATUS_CHANGE',
    name: 'Changement de statut',
    message: (payload) => {

      const status = EquipmentStatus.parse(payload.status);

      return (
        <Space>
          Le statut a été modifié en

          {/* Status */}
          {status ? (
            <Pin color={status.color}>
              {status.name}
            </Pin>
          ) : (
            <Unknown color="red" help="oœuf ">
              {payload.status}
            </Unknown>
          )}
        </Space>

      )
    },
    icon: <HistoryOutlined />
  },

  BATTERY_CHANGE: {
    type: 'BATTERY_CHANGE',
    name: 'Changement de batterie',
    message: (payload) => {
      const { battery } = payload;

      return (
        <Space>
          La référence de la batterie a été modifiée
          <Discrete>
            {battery}
          </Discrete>
        </Space>
      )
    },
    icon: <HistoryOutlined />
  },

  SYNC: {
    type: 'SYNC',
    name: 'Synchronisation',
    message: (payload) => "L'équipement a été synchronisé",
    icon: <CloudSyncOutlined />
  },

  FIRMWARE_UPDATE: {
    type: 'FIRMWARE_UPDATE',
    name: 'Mise à jour logiciel',
    message: (payload) => {

      const { version } = payload;
      const channel = Channel.parse(payload.channel);

      return (
        <Space>
          Le micrologiciel a été mis à jour en version

          {channel ? (
            <Link to={`/firmwares${API.queryString({ version: version, channel: channel.id })}`}>
              <Pin color={channel.color}>
                {version}
              </Pin>
            </Link>
          ) : (
            <Unknown help="Canal inconnu" color="red">
              {version}@{payload.channel}
            </Unknown>
          )}
        </Space>
      )
    },
    icon: <SyncOutlined />
  }
};

export default Enum(Universe, undefined, (type, values) => {
  return values.find((value) => value.type === type);
});
