import Enum from './_Enum';

let Universe = {

  /** DosiCase */

  SR: {
    id: 'SR',
    name: 'Source',
    category: 'Équipements',
    hasBoard: true
  },

  DM: {
    id: 'DM',
    name: 'Dosimètre',
    category: 'Équipements',
    hasBoard: true
  },

  RM: {
    id: 'RM',
    name: 'Radiamètre',
    category: 'Équipements',
    hasBoard: true
  },

  /** NucleoSim */

  EP: {
    id: 'EP',
    name: 'Écran',
    category: 'Équipements',
    hasBoard: true
  },

  CM: {
    id: 'CM',
    name: 'Contaminamètre',
    category: 'Équipements',
    hasBoard: true
  },

  PR: {
    id: 'PR',
    name: 'Perche radiamétrique',
    category: 'Accessoires',
    hasBoard: true
  },

  BR: {
    id: 'BR',
    name: 'Balise radiamétrique',
    category: 'Accessoires'
  },

  /** Conditioning */

  VA: {
    id: 'VA',
    name: 'Valise',
    category: 'Conditionnement',
    hasBoard: false
  },

  RC: {
    id: 'RC',
    name: 'Rack de chargement',
    category: 'Conditionnement',
    hasBoard: false
  }

};

export default Enum(Universe, null, (id, values) => {
  return values.find(kind => kind.id === id);
});
