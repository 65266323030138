import React from 'react'
import PropTypes from 'prop-types'

import { Tooltip } from 'antd';

import './style.scoped.css';

class Unknown extends React.Component {

  render() {

    const { help, children, ...rest } = this.props;

    return (
      <Tooltip title={help} {...rest}>
        <span className="unknown">
          {children}
        </span>
      </Tooltip>
    )
  }
}

Unknown.propTypes = {
  color: PropTypes.string.isRequired,
  help: PropTypes.string.isRequired
};

export default Unknown;
