import React from 'react'

import { withRouter } from "react-router-dom";

import {
  MailOutlined,
  PhoneOutlined,
  EditOutlined,
  PlusOutlined,
  DeleteOutlined,
  StarFilled,
  UserOutlined
} from '@ant-design/icons';

import { Skeleton, Input, Button, List, Card, Drawer, Popconfirm, Divider, Badge, Tooltip, Typography, Space } from 'antd';

const { Search } = Input;
const { Paragraph } = Typography;

import { ContactForm } from 'app/forms';

import { debounce } from 'lodash-es';

import { Flex, Empty } from 'app/components';

import { API } from 'app/services';

import { Typeof, Arrays, Functions } from 'app/utils';

import './style.scoped.css';

class ContactsTab extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      contacts: [],
      isFetching: true,
      isFormOpen: null,
      contactToEdit: null,
      search: '',
      criteria: '',
    }
  }

  componentDidMount = async() => {

    const { simulator } = this.props;

    const { customer } = simulator;

    try {

      let responseSimulator = await API.get(`/simulators/${simulator.id}/contacts`);

      let contacts = [...responseSimulator.data];

      if(customer) {

        let responseCustomer = await API.get(`/customers/${customer.id}/contacts`);

        contacts = [...responseCustomer.data.map((contact) => {
          return {
            ...contact,
            customer: customer.id
          }

        }), ...contacts]
      }

      this.setState({
        isFetching: false,
        contacts: contacts
      });

    } catch(error) {

      this.props.history.goBack();
    }
  }

  toggleForm = (open = false, contact = null) => {

    this.setState({
      isFormOpen: open,
      contactToEdit: contact
    })

  }

  handleCreateForm = (contact) => {

    const { contacts } = this.state;

    this.setState({
      contacts: [ ...contacts, contact]
    }, () => this.toggleForm(false));
  }

  handleUpdateForm = (updated) => {

    const { contacts } = this.state;

    this.setState({
      contacts: contacts.map((contact) => {
        return contact.id === updated.id ? updated : contact
      })
    }, () => this.toggleForm(false));
  }

  handleDelete = async () => {

    const { simulator } = this.props;
    const { contacts, contactToEdit } = this.state;

    // Delete contact
    await API.delete(`/simulators/${simulator.id}/contacts/${contactToEdit.id}`);

    this.setState({
      contacts: contacts.filter((contact) => contact.id !== contactToEdit.id)
    }, () => this.toggleForm(false));
  }

  handleSearch = (e) => {

    const { value } = e.target;

    this.setState({
      search: value
    });

    this.applySearch(value)
  }

  applySearch = debounce((value) => {

    this.setState({
      criteria: value.toLowerCase()
    });
  }, 250);

  render() {

    const { isFetching } = this.state;

    return (
      <>

        {isFetching ? (
          <Skeleton />
        ) : (
          this.renderContacts()
        )}

      </>
    );
  }

  renderContact = (contact) => {

    const { simulator } = this.props;
    const { id, lastname, firstname, email, phone } = contact;

    const isPrimary = contact.id === simulator.customer?.primary_contact?.id

    let actions = []

    // Mail action
    actions.push(
      <Tooltip title="Envoyer un courriel" key="mail">
        <a href={`mailto:${email}`}>
          <MailOutlined key="mail" />
        </a>
      </Tooltip>
    )

    if (phone) {

      // Phone action
      actions.push(
        <Tooltip title="Appeler" key="call">
          <a href={`tel:${phone}`}>
            <PhoneOutlined key="call" />
          </a>
        </Tooltip>
      )
    }

    {!contact.customer && (

      // Edit action
      actions.push(
        <Tooltip title="Modifier" key="edit">
          <EditOutlined key="edit" onClick={() => this.toggleForm(true, contact)} />
        </Tooltip>
      )
    )}

    let cardContacts = (
      <Card
        className={isPrimary ? "primary" : false}
        title={
          <Space>

            {isPrimary &&
              <StarFilled style={{ color: 'gold' }} />
            }

            {`${lastname} ${firstname}`}

          </Space>
        }
        hoverable
        actions={actions}
      >
        <List>

          {/* Email */}
          <List.Item>
            <Paragraph copyable={{ text: email }} style={{ margin: 0 }}>
              {email}
            </Paragraph>
          </List.Item>

          {/* Phone */}
          <List.Item>
            {phone ? (
              <Paragraph copyable={{ text: contact.phone }} style={{ margin: 0 }}>
                {Functions.breakUp(contact.phone, 2, '·')}
              </Paragraph>
            ) : (
              <Empty />
            )}
          </List.Item>

        </List>
      </Card>
    )

    return (
      <div className="contact-container" key={id}>
        {contact.customer ? (
          <Badge.Ribbon text={
            <Tooltip title={`Importé depuis « ${simulator.customer.company} »`}>
              <a onClick={() => this.props.history.push(`/customers/${contact.customer}#contacts`)} style={{ color: 'white' }}>
                  <UserOutlined />
              </a>
            </Tooltip>
            } color="blue" onClick={() => console.log("test")}>
            {cardContacts}
          </Badge.Ribbon>
        ) : (
          cardContacts
        )}
      </div>
    )
  }

  renderContacts = () => {

    const { simulator } = this.props;
    const primaryContact = simulator.customer?.primary_contact;

    const { isFormOpen, contactToEdit, search, criteria } = this.state;

    /** Indicates if the form is for contact editing. */
    const isEditing = Typeof.object(contactToEdit);

    const all = this.state.contacts.sort((a, b) => {

      if(a.customer === b.customer) {

        if (primaryContact) {

          if (a.id === primaryContact.id) {
            return -1;
          }

          if (b.id === primaryContact.id) {
            return 1;
          }
        }

        return a.id - b.id
      }

      return (b.customer ?? 0) - (a.customer ?? 0)
    });

    // let contacts = [contacts.find((contact) => contact.id === primary_contact.id), ...contacts.filter((contact) => contact.id !== primary_contact.id)];

    // const all = this.state.contacts;
    const visible = Arrays.filterByCriteria(all, criteria, (contact) => {

      return [
        `#${contact.id}`,
        contact.lastname,
        contact.firstname,
        contact.email,
        contact.phone
      ]
    })

    return (
      <>

        <Flex vertical space={16}>

          <Flex spaceBetween space={8}>

            <Search placeholder="Rechercher" allowClear onChange={this.handleSearch} value={search} />

          </Flex>

        <div className='contacts-container'>

          {/* Contacts */}
          {visible.map((contact) => this.renderContact(contact))}

          {/* Add contact */}
          <Card className="add-contact contact-container" hoverable onClick={() => this.toggleForm(true, null)}>
            <PlusOutlined />
            Nouveau contact
          </Card>

          <Divider />

        </div>

        </Flex>

        {/* Create form */}
        <Drawer title="Nouveau contact" visible={isFormOpen && !isEditing} onClose={() => this.toggleForm(false)}>

          {isFormOpen && !isEditing &&
            <ContactForm onSuccess={this.handleCreateForm} makeRequest={(_, data) => API.post(`/simulators/${simulator.id}/contacts`, data)} />
          }

        </Drawer>

        {/* Edit form */}
        <Drawer title="Modification du contact" visible={isFormOpen && isEditing} onClose={() => this.toggleForm(false)} extra={

          // Delete
          <Popconfirm title="Êtes-vous sûr de vouloir supprimer ce contact ?" onConfirm={this.handleDelete} okText="Oui" cancelText="Non">

            <Button shape="circle" type="danger">
              <DeleteOutlined />
            </Button>

          </Popconfirm>
        }>

          {isFormOpen && isEditing &&
            <ContactForm onSuccess={this.handleUpdateForm} source={contactToEdit} makeRequest={(contact, data) => API.put(`/simulators/${simulator.id}/contacts/${contact.id}`, data)} />
          }

        </Drawer>

      </>
    );
  }
}

export default withRouter(ContactsTab);
