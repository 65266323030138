import React from 'react'
import PropTypes from 'prop-types'

import { Flex } from 'app/components';

import './style.scoped.css';

class Pin extends React.Component {

  render() {

    const { color, children } = this.props;

    return (
      <Flex centerV space={6}>
        <div className="pin" style={{ backgroundColor: color }} />
        {children}
      </Flex>
    );
  }
}

export default Pin;
