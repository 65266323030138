import React from 'react'

import moment from 'moment';

import { Col, Row, Button, Form, Input, Select, DatePicker } from 'antd';

import { Pin } from 'app/components';
import { BoardStatus } from 'app/enums';

import { Notifications, Validation, Functions } from 'app/utils'

class BoardForm extends React.Component {

  constructor(props) {
    super(props);

    const { source } = props;

    this.state ={
      fields:[
        {name: ['id'],              value: source?.id },
        {name: ['production_date'], value: source?.production_date ? moment(source.production_date) : moment() },
        {name: ['batch_number'],    value: source?.batch_number },
        {name: ['ble_mac_address'], value: source?.ble_mac_address ? Functions.breakUp(source.ble_mac_address, 2, ':') : undefined },
        {name: ['status'],          value: source?.status ?? BoardStatus.OK.id },
        {name: ['factory_firmware'],    value: source?.factory_firmware }
      ],
      pending:false
    };
  }

  handleSubmit = async (data) => {

    const { source, makeRequest } = this.props;

    this.setState({
      pending: true
    });

    let newState = {
      pending: false
    };

    var onComplete;

    try {

      // Sanitize payload
      data.production_date = moment(data.production_date).format('YYYY-MM-DD');

      const regex = /[:-]/g;
      data.ble_mac_address = data.ble_mac_address?.replaceAll(regex, '');

      let response = await makeRequest(source, data);

      //Notify success
      onComplete = () => {
        this.props.onSuccess(response.data)
      }



    } catch (error) {

      try {

        // Validation
        newState.fields = Validation.parseValidationsErrors(this.state.fields, error);

      } catch (error) {

        // Unhandled error
        Notifications.error(
          "Oops, quelque chose s'est mal passé...",
          "Une erreur est survenue lors de la mise à jour des informations du client",
          error.message
        )
      }

    } finally {
      this.setState(newState, onComplete);
    }
  }

  handleChange = (_, fields) => {

    this.setState({
      fields: fields
    });
  }

  render(){

    const { fields, pending } = this.state;

    return(
      <Form
      name = "create-board"
      fields={fields}
      layout="vertical"
      onFieldsChange={this.handleChange}
      disabled={pending}
      onFinish={this.handleSubmit}
      >

        {/* BLE Mac address */}
        <Form.Item name="ble_mac_address" label="Adresse MAC BLE" required>
          <Input placeHolder="XX:XX:XX:XX:XX:XX" />
        </Form.Item>

        {/* Batch number */}
        <Form.Item name="batch_number" label="Numéro de lot" required>
          <Input />
        </Form.Item>

        {/* Factory Firmware */}
        <Form.Item name="factory_firmware" label="Micrologiciel d'usine">
          <Input placeholder="Ex: 1.0.1" />
        </Form.Item>

        <Row gutter={10}>

          <Col span={12}>

            {/* Production date */}
            <Form.Item name="production_date" label="Date de production" required>
              <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} />
            </Form.Item>

          </Col>

          <Col span={12}>

            {/* Status */}
            <Form.Item name="status" label="Statut" required>
              <Select>

                {BoardStatus.values().map((status) => {

                  return (
                    <Select.Option key={status.id} value={status.id}>
                      <Pin color={status.color}>
                        {status.name}
                      </Pin>
                    </Select.Option>
                  )
                })}

              </Select>
            </Form.Item>

          </Col>

        </Row>

        <Button form="create-board" type="primary" htmlType="submit" >
          Valider
        </Button>

      </Form>
    );
  }
}

export default BoardForm;

