import axios from 'axios';

import { Env } from 'app/env';

import { Auth } from 'app/services';
import { Typeof, Functions } from 'app/utils';

//--Constants
const API_URL = `${Env.API_URL}/backend/api/v1`;

const AXIOS = axios.create({
  baseURL: API_URL
});

class API {

  /**
   * Called on auth token change.
   * @param {string|null} token
   */
  static onTokenChange(token) {

    API.token = token;

    if(Typeof.string(token)) {
      AXIOS.defaults.headers['Authorization'] = `Bearer ${token}`;
    } else {
      delete AXIOS.defaults.headers['Authorization'];
    }
  }

  /**
   * Serializes an object for query string.
   * @param   {object|null} params
   * @returns {string}      a sanitized flat representation of the object like '?key=value' otherwise null
   */
  static queryString(params = null) {

    let encode = (key, value) => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    }

    if(Typeof.object(params)) {

      let entries = Object.entries(params);

      if(entries.length > 0) {

        return '?' + Object.entries(params).map(([key, value]) => {

          if(Typeof.array(value)) {

            return value.map((i) => {
              return encode(`${key}[]`, i);
            }).join('&');
          }

          return encode(key, value);

        }).join('&');
      }
    }

    return '';
  }

  static async get(route, query = null) {
    return AXIOS.get(`${route}${API.queryString(query)}`);
  }

  static async post(route, payload, query = null) {
    return AXIOS.post(`${route}${API.queryString(query)}`, payload);
  }

  static async put(route, payload, query = null) {
    return AXIOS.put(`${route}${API.queryString(query)}`, payload);
  }

  static async delete(route, query = null) {
    return AXIOS.delete(`${route}${API.queryString(query)}`);
  }

  static async open(route) {

    let response = await AXIOS.get(route, {
      responseType: 'arraybuffer'
    });

    var file = new Blob([response.data], { type: response.headers['content-type'] });
    let url = URL.createObjectURL(file);
    window.open(url, '_blank');
  }

  /**
   *
   * @param {string}      route
   * @param {object|null} query
   * @param {boolean}     authToken
   * @returns {string}    a
   */
  static url(route, query = null, authToken = false) {

    let url = `${API_URL}/`;

    //Replace leading slashes
    url += route.replace(/^\/+/, '');

    if(authToken) {

      let params = query ?? {};

      let token = API.token;

      if(Typeof.string(token)) {
        params['Bearer'] = token;
      }

      url += API.queryString(params);
    }

    return url;
  }

  /**
  * Parses violations from the given API error
  * @param {*} error
  */
  static violations(error) {

    let violations = {};

    if (Typeof.object(error.response) && Typeof.object(error.response.data) && Typeof.string(error.response.data.message)) {

      try {
        let causes = JSON.parse(error.response.data.message);

        causes.forEach((violation) => {

          if (!violations.hasOwnProperty(violation.field)) {
            violations[violation.field] = [];
          }

          violations[violation.field].push(Functions.capitalize(violation.cause));

        });
      }
      catch (exception) {
        console.error('Unable to parse violations: ' + exception.message);
      }
    }

    return violations;
  }
}

API.token = null;

export default API;
