import Enum from './_Enum';

let Universe = {

  DEBUG: {
    id: 'DEBUG',
    name: 'DEBUG',
    color: '#ffc107'
  },

  RELEASE: {
    id: 'RELEASE',
    name: 'RELEASE',
    color: '#8bc34a'
  }
};

export default Enum(Universe, Universe.CASE, (id, values) => {
  return values.find((value) => value.id === id);
});
