import React from 'react'
import PropTypes from 'prop-types'

import { Button, Form, Input, Select } from 'antd';
import { Col, Row } from 'antd';

import { Country, Locale } from 'app/enums'

import { Notifications, Validation } from 'app/utils'

class CustomerForm extends React.Component {

  constructor(props) {

    super(props);

    const { source } = props;

    let fields = [
      { name: ['company'],  value: source?.company },
      { name: ['address'],  value: source?.address },
      { name: ['zip_code'], value: source?.zip_code },
      { name: ['city'],     value: source?.city },
      { name: ['country'],  value: source?.country ?? Country.default.code },
      { name: ['locale'],   value: source?.locale ?? Locale.default.code }
    ]

    this.state = {
      pending: false,
      fields: fields
    };
  }

  handleSubmit = async (data) => {

    const { source, makeRequest } = this.props;

    this.setState({
      pending: true
    });

    let newState = {
      pending: false
    };

    var onComplete;

    try {

      let response = await makeRequest(source, data);

      //Notify success
      onComplete = () => {
        this.props.onSuccess(response.data)
      }

    } catch (error) {

      try {

        // Validation
        newState.fields = Validation.parseValidationsErrors(this.state.fields, error);

      } catch (error) {

        // Unhandled error
        Notifications.error(
          "Oops, quelque chose s'est mal passé...",
          "Une erreur est survenue lors de la mise à jour des informations du client",
          error.message
        )
      }

    } finally {
      this.setState(newState, onComplete);
    }
  }

  handleFieldsChange = (_, fields) => {

    this.setState({
      fields: fields
    });
  }

  render() {

    const { fields, pending } = this.state;

    return (
      <Form
        name="customer-form"
        fields={fields}
        layout="vertical"
        onFieldsChange={this.handleFieldsChange}
        disabled={pending}
        onFinish={this.handleSubmit}
      >

        {/* Company */}
        <Form.Item name="company" label="Société" required>
          <Input />
        </Form.Item>

        {/* Adress */}
        <Form.Item name="address" label="Adresse" required>
          <Input />
        </Form.Item>

        <Row gutter={10}>

          <Col span={12}>

            {/* Zip code */}
            <Form.Item name="zip_code" label="Code postal" required>
              <Input />
            </Form.Item>

          </Col>

          <Col span={12}>

            {/* City */}
            <Form.Item name="city" label="Ville" required>
              <Input />
            </Form.Item>

          </Col>

        </Row>

        <Row gutter={10}>

          <Col span={12}>

            {/* Country */}
            <Form.Item name="country" label="Pays" required>
              <Select>

                {Country.values().map((country) => {

                  return (
                    <Select.Option key={country.code} value={country.code}>
                      {country.name}
                    </Select.Option>
                  )
                })}

              </Select>
            </Form.Item>

          </Col>

          <Col span={12}>

            {/* Locale */}
            <Form.Item name="locale" label="Langue" required>
              <Select>

                {Locale.values().map((locale) => {

                  return (
                    <Select.Option key={locale.code} value={locale.code}>
                      {locale.name}
                    </Select.Option>
                  )
                })}

              </Select>
            </Form.Item>

          </Col>

        </Row>

        <Button form="customer-form" type="primary" htmlType="submit">
          Valider
        </Button>

      </Form>
    );
  }
}

export default CustomerForm;
