import React from 'react'
import PropTypes from 'prop-types';

import { Route } from "react-router-dom";

class PublicRoute extends React.Component {

  render() {
    //Extract page and the rest of props
    const { page: Page, dummy, ...rest } = this.props;

    return (
      <Route {...rest} render={(props) =>
        <Page {...props} />
      }/>
    )
  }
}

PublicRoute.propTypes = {
  page: PropTypes.element.isRequired
};

export default PublicRoute;
