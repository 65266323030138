import React from 'react'

import moment from 'moment';

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'

import {
  EyeOutlined,
  EyeInvisibleOutlined,
  InboxOutlined
} from '@ant-design/icons';

import { Button, Form, Input, Select, Badge, DatePicker, Upload, Switch, Space } from 'antd';

const { Dragger } = Upload;
const { TextArea } = Input;
const { Option } = Select;

import { Discrete, Flex } from 'app/components';

import { Notifications, Validation } from 'app/utils';

import './style.scoped.css';

class LogbookForm extends React.Component {

  constructor(props) {

    super(props);

    const { source } = props;

    this.state ={
      fields: [
        { name: ['timestamp'], value: source?.timestamp ? moment(source.timestamp) : moment() },
        { name: ['title'],     value: source?.title },
        { name: ['content'],   value: source?.content },
        { name: ['tags'] }
      ],
      pending: false,
      showPreview: false
    };
  }

  handleSubmit = async (data) => {

    //Convert to UTC
    data.timestamp = moment.utc(data.timestamp)

    const { source, makeRequest } = this.props;

    this.setState({
      pending: true
    });

    let newState = {
      pending: false
    };

    var onComplete;

    try {

      let formData = new FormData();

      const { files } = data;

      // Append attachments
      if(files) {

        delete data.files;

        files.fileList.forEach(file => {
          formData.append("attachments[]", file.originFileObj);
        });
      }

      data.timestamp = moment(data.timestamp).format('YYYY-MM-DDTHH:mm:ssZ');

      formData.append("data", JSON.stringify(data));

      let response = await makeRequest(source, formData);

      //Notify success
      onComplete = () => {
        this.props.onSuccess(response.data)
      }

    } catch (error) {

      try {

        // Validation
        newState.fields = Validation.parseValidationsErrors(this.state.fields, error);

      } catch (error) {

        // Unhandled error
        Notifications.error(
          "Oops, quelque chose s'est mal passé...",
          "Une erreur est survenue lors de l'ajout de l'entrée",
          error.message
        )
      }

    } finally {
      this.setState(newState, onComplete);
    }
  }

  handleFieldsChange = (_, fields) => {

    this.setState({
      fields: fields
    });
  }

  handlePreviewChange = (checked) => {

    this.setState({
      showPreview: checked
    });
  }

  render() {

    const { tags } = this.props;
    const { fields, pending, showPreview } = this.state;

    return(
      <Form
        name="logbook-form"
        fields={fields}
        layout="vertical"
        onFieldsChange={this.handleFieldsChange}
        disabled={pending}
        onFinish={this.handleSubmit}
        requiredMark
      >

        {/* Title */}
        <Form.Item name="title" label="Titre" required>
          <Input />
        </Form.Item>

        {/* Content */}
        <Form.Item name="content" className="content"
          label={
            <Flex spaceBetween>
              Contenu

              <Space>

                Prévisualiser

                <Switch
                  checked={showPreview}
                  onChange={this.handlePreviewChange}
                  checkedChildren={<EyeOutlined />}
                  unCheckedChildren={<EyeInvisibleOutlined />}
                />
              </Space>
            </Flex>
          }
          extra={
            <Flex alignRight>
              <a href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet" target="_blank">Markdown Cheatsheet</a>
            </Flex>
          }
        >
          {/* Preview */}
          {showPreview ? (
            <div className="content-preview">
              <ReactMarkdown remarkPlugins={[remarkGfm]}>{fields.find((field) => field.name == "content")?.value}</ReactMarkdown>
            </div>
          ) : (
            <TextArea style={{ minHeight: '150px' }} />
          )}


        </Form.Item>

        {/* Tags */}
        <Form.Item name="tags" label="Étiquette(s)">

          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Aucune étiquette"
            optionLabelProp="label"
          >
            {tags.map((tag) => {
              return (
                <Option value={tag.id} label={tag.name} key={tag.id} color={tag.color}>
                  <Badge count={tag?.name ?? rawTag} style={{ backgroundColor: tag.color }} />
                </Option>
              )
            })}
          </Select>

        </Form.Item>

        {/* Attachments */}
        <Form.Item name="files" label="Pièces jointes">
          <Dragger multiple beforeUpload={() => false}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <Discrete>
              Cliquez ou déposez des fichiers dans cette zone
            </Discrete>
          </Dragger>
        </Form.Item>

        {/*Date*/}
        <Form.Item name="timestamp" label="Horodatage">
          <DatePicker format="[Le] DD/MM/YYYY [à] HH:mm:ss" showTime={{ format: 'HH:mm:ss' }} style={{ width: '100%' }} />
        </Form.Item>

        <Button form = "logbook-form" type="primary" htmlType="submit" >
          Valider
        </Button>

      </Form>
    );
  }
}

export default LogbookForm;

