import React from 'react'
import PropTypes from 'prop-types'

import { Timeline } from 'app/components';

import { EquipmentEvent, EquipmentLogbookTag } from 'app/enums';

class TimelineTab extends React.Component {

  render() {

    const { equipment } = this.props;

    return (
      <Timeline origin={`/equipments/${equipment.serial}`} events={EquipmentEvent} tags={EquipmentLogbookTag} />
    );
  }
}

export default TimelineTab;
