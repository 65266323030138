import React from 'react'
import PropTypes from 'prop-types'

import { Button, Form, Input } from 'antd';

import { Functions, Notifications, Validation } from 'app/utils';

class SimulatorDeviceForm extends React.Component {

  constructor(props) {

    super(props);

    const { source } = props;

    let fields = [
      { name: ['model'], value: source?.model ?? "" },
      { name: ['serial'], value: source?.serial ?? "" }
    ]

    this.state = {
      pending: false,
      fields: fields
    };
  }

  handleSubmit = async (data) => {

    // Sanitize
    Functions.sanitize(data, 'model', true);
    Functions.sanitize(data, 'serial', true);

    const { source, makeRequest } = this.props;

    this.setState({
      pending: true
    });

    let newState = {
      pending: false
    };

    var onComplete;

    try {

      let response = await makeRequest(source, data);

      //Notify success
      onComplete = () => {
        this.props.onSuccess(response.data)
      }

    } catch (error) {

      try {

        // Validation
        newState.fields = Validation.parseValidationsErrors(this.state.fields, error);

      } catch (error) {

        // Unhandled error
        Notifications.error(
          "Oops, quelque chose s'est mal passé...",
          "Une erreur est survenue lors de la mise à jour des informations du simulateur",
          error.message
        )
      }

    } finally {
      this.setState(newState, onComplete);
    }
  }

  handleFieldsChange = (_, fields) => {

    this.setState({
      fields: fields
    });
  }


  render() {

    const { fields, pending } = this.state;

    return (
      <Form
        name="simulator-device-form"
        fields={fields}
        layout="vertical"
        onFieldsChange={this.handleFieldsChange}
        disabled={pending}
        onFinish={this.handleSubmit}
      >

        {/* Channel */}
        <Form.Item name="model" label="Modèle">
          <Input />
        </Form.Item>

        {/* Channel */}
        <Form.Item name="serial" label="N° de série">
          <Input />
        </Form.Item>

        <Button form="simulator-device-form" type="primary" htmlType="submit">
          Valider
        </Button>

      </Form>
    );
  }
}

export default SimulatorDeviceForm;
