import React from 'react'
import PropTypes from 'prop-types'

import {
  StarFilled,
  StarOutlined,
  MailOutlined,
  PhoneOutlined,
  EditOutlined,
  PlusOutlined,
  DeleteOutlined
} from '@ant-design/icons';

import { Skeleton, Tooltip, Button, List, Card, Drawer, Popconfirm, Typography } from 'antd';

const { Paragraph } = Typography;

import { Empty } from 'app/components';

import { ContactForm } from 'app/forms';

import { API } from 'app/services';

import { Typeof, Functions } from 'app/utils';

import './style.scoped.css';

class ContactsTab extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      contacts: [],
      isFetching: true,
      isFormOpen: null,
      contactToEdit: null
    }
  }

  componentDidMount = async() => {

    const { customer } = this.props;

    try {

      let response = await API.get(`/customers/${customer.id}/contacts`);

      this.setState({
        isFetching: false,
        contacts: response.data
      });

    } catch {

      this.props.history.goBack();
    }
  }

  handlePrimaryContact = async (contact) => {

    const { customer, onCustomerUpdate } = this.props;

    try {

      let isPrimary = contact.id === customer.primary_contact?.id;

      // Toggle primary contact
      await API.post(`/customers/${customer.id}/contacts/${contact.id}/primary`)

      onCustomerUpdate({
        ...customer,
        primary_contact: isPrimary ? undefined : contact
      })

    } catch {

    }
  }

  toggleForm = (open = false, contact = null) => {

    this.setState({
      isFormOpen: open,
      contactToEdit: contact
    })

  }

  handleCreateForm = (contact) => {

    const { contacts } = this.state;

    this.setState({
      contacts: [ ...contacts, contact]
    }, () => this.toggleForm(false));
  }

  handleUpdateForm = (updated) => {

    const { contacts } = this.state;

    this.setState({
      contacts: contacts.map((contact) => {
        return contact.id === updated.id ? updated : contact
      })
    }, () => this.toggleForm(false));
  }

  handleDelete = async () => {

    const { customer } = this.props;
    const { contacts, contactToEdit } = this.state;

    // Delete contact
    await API.delete(`/customers/${customer.id}/contacts/${contactToEdit.id}`);

    this.setState({
      contacts: contacts.filter((contact) => contact.id !== contactToEdit.id)
    }, () => this.toggleForm(false));
  }

  render() {

    const { isFetching } = this.state;

    return (
      <>

        {isFetching ? (
          <Skeleton />
        ) : (
          this.renderContacts()
        )}

      </>
    );
  }

  renderContact = (contact) => {

    const { customer } = this.props;
    const { id, lastname, firstname, email, phone } = contact;

    const isPrimary = contact.id === customer.primary_contact?.id

    let actions = []

    // Mail action
    actions.push(
      <Tooltip title="Envoyer un courriel">
        <a href={`mailto:${email}`}>
          <MailOutlined key="mail" />
        </a>
      </Tooltip>
    )

    if (phone) {

      // Phone action
      actions.push(
        <Tooltip title="Appeler">
          <a href={`tel:${phone}`}>
            <PhoneOutlined key="call" />
          </a>
        </Tooltip>
      )
    }

    // Edit action
    actions.push(
      <Tooltip title="Modifier">
        <EditOutlined key="edit" onClick={() => this.toggleForm(true, contact)} />
      </Tooltip>
    )

    let primaryToggle = (
      <Tooltip title={isPrimary ? "Retirer contact principal" : "Définir comme contact principal"}>
        <Button type="text" shape="circle" onClick={() => this.handlePrimaryContact(contact)}>
          {isPrimary ? <StarFilled style={{color: "gold" }}/> : <StarOutlined />}
        </Button>
      </Tooltip>
    )

    return (
      <Card key={id}
        title={`${lastname} ${firstname}`}
        hoverable
        extra={primaryToggle}
        actions={actions}
        className={isPrimary ? "primary" : false}
      >
        <List>

          {/* Email */}
          <List.Item>
            <Paragraph copyable={{ text: email }} style={{ margin: 0 }}>
              {email}
            </Paragraph>
          </List.Item>

          {/* Phone */}
          <List.Item>
            {phone ? (
              <Paragraph copyable={{ text: phone }} style={{ margin: 0 }}>
                {Functions.breakUp(phone, 2, '·')}
              </Paragraph>
            ) : (
              <Empty />
            )}
          </List.Item>

        </List>
      </Card>
    )
  }

  renderContacts = () => {

    const { customer } = this.props;
    const { primary_contact } = customer;

    const { isFormOpen, contactToEdit } = this.state;

    /** Indicates if the form is for contact editing. */
    const isEditing = Typeof.object(contactToEdit);

    let contacts = this.state.contacts;
    let primaryContact = Typeof.object(primary_contact) ? contacts.find((contact) => contact.id === primary_contact.id) : undefined;

    //Shift primary contact first
    if(primaryContact) {
      contacts = [primaryContact, ...contacts.filter((contact) => contact.id !== primaryContact.id)];
    }

    return (
      <>
        <div className='contacts-container'>

          {/* Contacts */}
          {contacts.map((contact) => {

            return (
              <div className="contact-container">
                {this.renderContact(contact, contact.id === primary_contact?.id)}
              </div>
            )

          })}

          {/* Add contact */}
          <Card className="add-contact contact-container" hoverable onClick={() => this.toggleForm(true, null)}>
            <PlusOutlined />
            Nouveau contact
          </Card>

        </div>

        {/* Create form */}
        <Drawer title="Nouveau contact" visible={isFormOpen && !isEditing} onClose={() => this.toggleForm(false)}>

          {isFormOpen && !isEditing &&
            <ContactForm onSuccess={this.handleCreateForm} makeRequest={(_, data) => API.post(`/customers/${customer.id}/contacts`, data)} />
          }

        </Drawer>

        {/* Edit form */}
        <Drawer title="Modification du contact" visible={isFormOpen && isEditing} onClose={() => this.toggleForm(false)} extra={

          // Delete
          <Popconfirm title="Êtes-vous sûr de vouloir supprimer ce contact ?" onConfirm={this.handleDelete} okText="Oui" cancelText="Non">

            <Button shape="circle" type="danger">
              <DeleteOutlined />
            </Button>

          </Popconfirm>
        }>

          {isFormOpen && isEditing &&
            <ContactForm onSuccess={this.handleUpdateForm} source={contactToEdit} makeRequest={(contact, data) => API.put(`/customers/${customer.id}/contacts/${contact.id}`, data)} />
          }

        </Drawer>

      </>
    );
  }
}

export default ContactsTab;
