import React from 'react'
import PropTypes from 'prop-types'

import { Col, Row } from 'antd';

import { Flex } from 'app/components';

import {
  CardBoard
} from './cards';

class InformationsTab extends React.Component {

  render() {

    const { equipment, onEquipmentUpdate } = this.props;

    return (
      <Flex vertical space={16}>

        <Row gutter={[16, 16]}>

          {/* Board */}
          <Col span={10}>
            <CardBoard equipment={equipment} onEquipmentUpdate={onEquipmentUpdate} />
          </Col>

        </Row>

      </Flex>
    );
  }
}

export default InformationsTab;
