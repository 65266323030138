import React from 'react'

import moment from 'moment';

import {
  StopOutlined,
  PlusOutlined
} from '@ant-design/icons';

import { Card, Button, Statistic, Empty, Tooltip, Popconfirm, Divider, Skeleton, Drawer, Timeline } from 'antd';

import { Flex, Pin, Discrete } from 'app/components';
import { WarrantyForm } from 'app/forms';

import { Notifications } from 'app/utils';

import { WarrantyStatus } from 'app/enums';

import { API } from 'app/services';

import './style.scoped.css';

class WarrantiesTab extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      isFetching: true,
      warranties: [],
      isFormOpen: false
    };
  }

  toggleForm = () => {

    this.setState({
      isFormOpen: !this.state.isFormOpen
    });
  }

  handleFormSuccess = (warranty) => {

    const { simulator, onSimulatorUpdate } = this.props;

    const { warranties } = this.state;

    this.setState({
      warranties: [warranty, ...warranties.map((other, i) => {

        if (i == 0 && WarrantyStatus.parse(other.status)?.voidable) {

          return {
            ...other,
            status: WarrantyStatus.VOIDED.id
          };
        }

        return other;
      })]
    }, (

      onSimulatorUpdate({
        ...simulator,
        active_warranty: warranty
      }, this.toggleForm)

    ));

  }

  componentDidMount = async () => {

    const { simulator } = this.props;

    try {

      let response = await API.get(`/simulators/${simulator.id}/warranties`);

      this.setState({
        warranties: response.data,
        isFetching: false
      })

    } catch(error) {

      // Show error
      Notifications.error(
        "Oops, quelque chose s'est mal passé...",
        "Une erreur est survenue lors de la récupération des garanties",
        error.message
      )

    }
  }

  handleVoid = async () => {

    const { simulator, onSimulatorUpdate } = this.props;
    const { warranties } = this.state;

    try {

      await API.delete(`/simulators/${simulator.id}/warranty`);

      let newWarranties = warranties.map((warranty, k) => {
        if(k === 0){
          return {
            ...warranty,
            status: WarrantyStatus.VOIDED.id
          }
        } else {
          return warranty
        }
      });

      this.setState({
        warranties: newWarranties
      }, () => {

        onSimulatorUpdate({
          ...simulator,
          active_warranty: {
            ...simulator.active_warranty,
            status: WarrantyStatus.VOIDED.id
          }
        })
      });

    } catch(error) {

    }
  }

  renderWarranty = (warranty, isActive) => {

    const status = WarrantyStatus.parse(warranty.status);

    let extra = null;

    if(isActive) {
      extra = (
        <Tooltip title="Interrompre la garantie">
          <Popconfirm
            title="Êtes-vous sûr de vouloir interrompre la garantie en cours ?"
            onConfirm={this.handleVoid}
            okText="Oui"
            cancelText="Annuler"
          >
            <Button type="danger" shape="circle">
              <StopOutlined />
            </Button>
          </Popconfirm>
        </Tooltip>
      )
    }

    return (
      <Card
        hoverable
        title={isActive ? "Garantie en cours" : false}
        extra={extra}
      >
        <Flex spaceBetweenH wrap alignBottom>

          {/* State */}
          <Statistic title="Statut" value={status?.name ?? warranty.status} prefix={
            <Pin color={status?.color} />
          } />

          {/* Duration */}
          <Statistic title="Durée" value={`${warranty.duration} mois`} />

          {/* Remaining */}
          {warranty.status === 'active' && warranty.remaining > 0 &&
            <Statistic title="Jour(s) restant(s)" value={warranty.remaining > 0 ? warranty.remaining : "Dernier jour"} />
          }

        </Flex>

        <Flex spaceBetweenH wrap>

          {/* Remaining */}
          <Statistic title="À partir du" value={`${moment(warranty.since).format('DD/MM/YYYY')}`} />

          {/* Remaining */}
          <Statistic title="Jusqu'au" value={`${moment(warranty.end).format('DD/MM/YYYY')}`} />

        </Flex>

        {/* Timestamp */}
        <Flex alignRight>

          <Discrete>
            <span>Ajouté le {`${moment(warranty.date_create).format('DD/MM/YYYY [à] HH:mm:ss')}`}</span>
          </Discrete>

        </Flex>

      </Card>
    )
  }

  renderWarranties = () => {

    const { simulator } = this.props;
    const { warranties, isFormOpen } = this.state;

    const activeWarranty = warranties.find((warranty, i) => {
      let status = WarrantyStatus.parse(warranty.status);
      return (i == 0) && status?.voidable;
    })

    const previousWarranties = activeWarranty ? warranties.slice(1, warranties.length) : warranties;

    return (

      <Flex vertical space={16} id="warranties">

        <Divider>
          <Flex space={8} centerV>

            {/* New warranty */}
            <Tooltip title="Nouvelle garantie">
              <Button key={1} type="primary" shape="circle" onClick={this.toggleForm}>
                <PlusOutlined />
              </Button>
            </Tooltip>

          </Flex>

        </Divider>

        {/* Active warranty */}
        {activeWarranty ? (

          <>
            {this.renderWarranty(activeWarranty, true)}
          </>

        ) : (

          <Empty description="Hors garantie" />
        )}

        <Timeline mode="alternate">

          {/* Previous warranties */}
          {previousWarranties.length > 0 &&
            <>
            <Divider>
              Garantie(s) précédente(s)
            </Divider>

            {previousWarranties.length > 0 &&
              <>
                {previousWarranties.map((warranty, i) => {

                  return (
                    <Timeline.Item key={i}>
                      {this.renderWarranty(warranty, false)}
                    </Timeline.Item>
                  )

                })}
              </>
            }

          </>
        }

        </Timeline>

        {/* Device form */}
        <Drawer title="Nouvelle garantie" placement="right" visible={isFormOpen} onClose={this.toggleForm} maskClosable={false} >

          {isFormOpen &&
            <WarrantyForm onSuccess={this.handleFormSuccess} makeRequest={(data) => API.post(`/simulators/${simulator.id}/warranty`, data)} />
          }

        </Drawer>

      </Flex>
    )
  }

  render() {

    const { isFetching } = this.state;

    return (
      <>
        {isFetching ? (
          <Skeleton />
        ) : (
          <>
            {this.renderWarranties()}
          </>
        )}
      </>
    )
  }
}

export default WarrantiesTab;
