import React from 'react'
import PropTypes from 'prop-types'

import { withRouter, Link } from "react-router-dom";

import {
  UserOutlined,
  LockOutlined
} from '@ant-design/icons';

import { Modal, Form, Input, Button, Result } from 'antd';

import { Notifications } from 'app/utils';
import { API } from 'app/services';

class UserActivatePage extends React.Component {

  constructor(props) {
    super(props);

    const urlParams = new URLSearchParams(window.location.search);
    let token = urlParams.get('token');

    //No token
    if (!token) {
      props.history.push('/login');
    }

    this.state = {
      fields: [
        { name: ['username'] },
        { name: ['password'] }
      ],
      token: token,
      pending: false,
      success: false
    };
  }

  handleFinish = async (data) => {

    const { token } = this.state;

    this.setState({
      pending: true
    });

    let newState = {
      pending: false
    };

    try {

      let payload = {
        token: token,
        username: data.username,
        password: data.password
      }

      //Reset Password
      await API.post('/users/activate', payload);

      newState.success = true;

    } catch (error) {

      // Unhandled error
      Notifications.error(
        "Oops, quelque chose s'est mal passé...",
        "Une erreur est survenue lors de la création de votre compte",
        error.message
      )

    } finally {
      this.setState(newState);
    }
  }

  handleChange = (_, fields) => {

    this.setState({
      fields: fields
    });
  }

  renderForm() {

    const { fields, pending } = this.state;

    return (
      <Modal title="Création de votre compte" visible closable={false} centered footer={
        <Button form="activate" type="primary" htmlType="submit" disabled={pending}>
          Valider
        </Button>
      }>
        <Form disabled={pending} name="activate" onFinish={this.handleFinish} fields={fields} onFieldsChange={this.handleChange} layout="vertical">

          {/* Username */}
          <Form.Item name="username" label="Nom d'utilisateur" required>
            <Input prefix={<UserOutlined className="site-form-item-icon" />} />
          </Form.Item>

          {/* Password */}
          <Form.Item name="password" label="Mot de passe" required
            rules={[
              {
                required: true,
                message: 'Veuillez saisir votre mot de passe',
              },
            ]}
            hasFeedback
          >
            <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} />
          </Form.Item>

          {/* Confirm password */}
          <Form.Item
            name="confirm"
            label="Confirmer le nouveau mot de passe"
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Veuillez saisir votre mot de passe',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(new Error('Les mots de passe ne correspondent pas'));
                },
              }),
            ]}
            required
          >
            <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} />

          </Form.Item>

        </Form>

      </Modal>
    )
  }

  renderSuccess() {

    return (
      <Modal title="Création de votre compte" visible closable={false} centered footer={false}>

        <Result
          status="success"
          title="Informations enregistrées"
          subTitle="Vous pouvez désormez vous connecter"
          extra={
            <Link to="/login">
              <Button type="primary" key={"confirmation"}>
                Se connecter
              </Button>
            </Link>
          }
        />

      </Modal>
    )
  }

  render() {

    const { success } = this.state;

    if (success) {
      return this.renderSuccess();
    }

    return this.renderForm();
  }
}

export default withRouter(UserActivatePage);
