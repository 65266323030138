import Enum from './_Enum';

let Universe = {

  AFTER_SALES: {
    id: 'AFTER_SALES',
    name: 'SAV',
    color: 'lightblue'
  }
};

export default Enum(Universe, undefined, (id, values) => {
  return values.find((value) => value.id === id);
});
