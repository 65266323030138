import React from 'react'
import PropTypes from 'prop-types'

import { Space } from 'antd';

import { Pin, Discrete, Empty, Flex, Unknown } from 'app/components';

import { WarrantyStatus } from 'app/enums';

import moment from 'moment';

class WarrantySummary extends React.Component {

  render() {

    const { warranty } = this.props;

    if (warranty) {

      let status = WarrantyStatus.parse(warranty.status);

      return (
        <>
          <Pin color={status?.color ?? 'red'}>
            <Flex space={4} centerV>
              {status?.name ?? <Unknown help="Statut inconnu">{warranty.status}</Unknown>}

              {/* Remaining */}
              {status === WarrantyStatus.ACTIVE &&
                <Discrete>
                  {warranty.remaining} jour(s) restant(s)
                </Discrete>
              }

              {/* Since */}
              {status === WarrantyStatus.COMING &&
                <Discrete>
                  {`à partir du ${moment(warranty.since, 'YYYY-MM-DD').format('DD/MM/YYYY')}`}
                </Discrete>
              }

            </Flex>

          </Pin>

          <Discrete>
            <Space size={4}>

              {/* Duration */}
              {`${warranty.duration} mois`}

              <Empty />

              {/* End */}
              {`${warranty.remaining > 0 ? "Jusqu'au" : "Depuis le"} ${moment(warranty.end, 'YYYY-MM-DD').format('DD/MM/YYYY')}`}
            </Space>
          </Discrete>

        </>
      )

    } else {

      return (
        <Empty />
      )

    }
  }
}

export default WarrantySummary;
