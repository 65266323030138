import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom';

import {
  StopOutlined,
  PlusOutlined
} from '@ant-design/icons';

import { Card, Button, Statistic, Empty, Drawer, Space, Tooltip, Popconfirm } from 'antd';

import { Flex, Pin, Discrete } from 'app/components';

import { WarrantyForm } from 'app/forms';

import { WarrantyStatus } from 'app/enums';

import { API } from 'app/services';

import moment from 'moment';

class CardWarranty extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      isFormOpen: false
    };
  }

  toggleForm = () => {

    this.setState({
      isFormOpen: !this.state.isFormOpen
    });
  }

  handleFormSuccess = (warranty) => {

    const { simulator, onSimulatorUpdate } = this.props;

    onSimulatorUpdate({
      ...simulator,
      active_warranty: warranty
    }, this.toggleForm);
  }

  handleVoid = async () => {

    const { simulator, onSimulatorUpdate } = this.props;

    try {

      await API.delete(`/simulators/${simulator.id}/warranty`);

      onSimulatorUpdate({
        ...simulator,
        active_warranty: {
          ...simulator.active_warranty,
          status: WarrantyStatus.VOIDED.id
        }
      })

    } catch(error) {

    }
  }

  render() {

    const { simulator } = this.props;
    const { isFormOpen } = this.state;

    const warranty = simulator.active_warranty;
    const status = WarrantyStatus.parse(warranty?.status);

    return (
      <Card hoverable
        title={warranty ? status?.voidable ? "Garantie en cours" : "Garantie précédente" : "Garantie en cours"}
        extra={
          <Space>

            {/* Void the warranty */}
            {(status?.voidable) &&
              <Tooltip title="Interrompre la garantie">
                <Popconfirm
                  title="Êtes-vous sûr de vouloir interrompre la garantie en cours ?"
                  onConfirm={this.handleVoid}
                  okText="Oui"
                  cancelText="Annuler"
                >
                  <Button type="danger" shape="circle">
                    <StopOutlined />
                  </Button>
                </Popconfirm>
              </Tooltip>
            }

            {/* New warranty */}
            <Tooltip title="Nouvelle garantie">
              <Button type="primary" shape="circle" onClick={this.toggleForm}>
                <PlusOutlined />
              </Button>
            </Tooltip>
          </Space>
        }
      >
        {warranty ? (
          <>
            <Flex spaceBetweenH wrap alignBottom>

              {/* State */}
              <Statistic title="Statut" value={status?.name ?? warranty.status} prefix={
                <Pin color={status?.color} />
              } />

              {/* Duration */}
              <Statistic title="Durée" value={`${warranty.duration} mois`} />

              {/* Remaining */}
              {warranty.status === 'active' && warranty.remaining > 0 &&
                <Statistic title="Jour(s) restant(s)" value={warranty.remaining > 0 ? warranty.remaining : "Dernier jour"}/>
              }

            </Flex>

            <Flex spaceBetweenH wrap>

              {/* Remaining */}
              <Statistic title="À partir du" value={`${moment(warranty.since).format('DD/MM/YYYY')}`} />

              {/* Remaining */}
              <Statistic title="Jusqu'au" value={`${moment(warranty.end).format('DD/MM/YYYY')}`} />

            </Flex>

            {/* Timestamp */}
            <Flex alignRight>

              <Discrete>
                <span>Ajouté le {`${moment(warranty.date_create).format('DD/MM/YYYY [à] HH:mm:ss')}`}</span>
              </Discrete>

            </Flex>

            {/* History */}
            <Flex alignRight>
              <Link to={`/simulators/${simulator.id}#warranties`}>
                Voir l'historique
              </Link>
            </Flex>

          </>
        ) : (
          <Empty description="Hors garantie" />
        )}

        {/* Device form */}
        <Drawer title="Nouvelle garantie" placement="right" visible={isFormOpen} onClose={this.toggleForm} maskClosable={false} >

          {isFormOpen &&
            <WarrantyForm onSuccess={this.handleFormSuccess} makeRequest={(data) => API.post(`/simulators/${simulator.id}/warranty`, data)} />
          }

        </Drawer>
      </Card>
    )
  }
}

export default CardWarranty;
