import React from 'react'
import PropTypes from 'prop-types'

import { Form, Input, Button, Row, Col } from 'antd'

import { Notifications, Validation } from 'app/utils';

class ContactForm extends React.Component {

  constructor(props) {
    super(props)

    const { source } = props;

    let fields = [
      { name: ['lastname'],  value: source?.lastname },
      { name: ['firstname'], value: source?.firstname },
      { name: ['email'],     value: source?.email },
      { name: ['phone'],     value: source?.phone }
    ]

    this.state = {
      pending: false,
      fields: fields
    }
  }

  handleSubmit = async (data) => {

    const { source, makeRequest } = this.props;

    this.setState({
      pending: true
    });

    let newState = {
      pending: false
    };

    var onComplete;

    try {

      let response = await makeRequest(source, data);

      //Notify success
      onComplete = () => {
        this.props.onSuccess(response.data)
      }

    } catch (error) {

      try {

        // Validation
        newState.fields = Validation.parseValidationsErrors(this.state.fields, error);

      } catch (error) {

        // Unhandled error
        Notifications.error(
          "Oops, quelque chose s'est mal passé...",
          "Une erreur est survenue lors de la mise à jour des informations du contact",
          error.message
        )
      }

    } finally {
      this.setState(newState, onComplete);
    }
  }

  handleFieldsChange = (_, fields) => {

    this.setState({
      fields: fields
    });
  }

  render() {

    const { fields, pending } = this.state;

    return (
      <Form
        name="contact-form"
        fields={fields}
        layout="vertical"
        onFieldsChange={this.handleFieldsChange}
        disabled={pending}
        onFinish={this.handleSubmit}
      >

        <Row gutter={10}>

          <Col span={12}>

            {/* Lastname */}
            <Form.Item name="lastname" label="Nom" required>
              <Input />
            </Form.Item>

          </Col>

          <Col span={12}>

            {/* Firstname */}
            <Form.Item name="firstname" label="Prénom" required>
              <Input />
            </Form.Item>

          </Col>

        </Row>

        {/* Email */}
        <Form.Item
          name="email"
          label="Adresse e-mail"
          required
          rules={[
            {
              type: 'email',
              message: 'Doit être une adresse e-mail valide',
            }
          ]}>
          <Input />
        </Form.Item>

        {/* Phone */}
        <Form.Item
          name="phone"
          label="N° de téléphone"
        >
          <Input />
        </Form.Item>

        <Button form="contact-form" type="primary" htmlType="submit">
          Valider
        </Button>

      </Form>
    );
  }
}

export default ContactForm;
