import React from 'react'
import PropTypes from 'prop-types'

import { Col, Row } from 'antd';

import { Flex } from 'app/components';

import {
  CardActivation,
  CardGeneral,
  CardApplication,
  CardWarranty,
  CardDevice,
  CardPrimaryContact
} from './cards';

import { Typeof } from 'app/utils';

import './style.scoped.css';

class InformationsTab extends React.Component {

  render() {

    const { simulator, onSimulatorUpdate} = this.props;

    const { customer } = simulator;
    const primaryContact = customer?.primary_contact;

    return (
      <Flex vertical space={16}>

        <Row gutter={[16, 16]}>

          {/* General */}
          <Col span={8}>
            <CardGeneral simulator={simulator} onSimulatorUpdate={onSimulatorUpdate} />
          </Col>

          {/* Activation */}
          <Col span={8}>
            <CardActivation simulator={simulator} onSimulatorUpdate={onSimulatorUpdate} />
          </Col>

          {/* Application */}
          <Col span={8}>
            <CardApplication simulator={simulator} />
          </Col>

          {/* Device */}
          <Col span={8}>
            <CardDevice simulator={simulator} onSimulatorUpdate={onSimulatorUpdate} />
          </Col>

          {/* Warranty */}
          <Col span={8}>
            <CardWarranty simulator={simulator} onSimulatorUpdate={onSimulatorUpdate} />
          </Col>

          {/* Primary contact */}
          {primaryContact &&
            <Col span={8}>
              <CardPrimaryContact contact={primaryContact} />
            </Col>
          }

        </Row>
      </Flex>
    );
  }
}

export default InformationsTab;
