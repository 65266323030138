import React from 'react'
import PropTypes from 'prop-types';

import { Route } from "react-router-dom";

import { DummyPage } from 'app/pages';

class DummyRoute extends React.Component {

  render() {

    //Extract component and the rest of props
    const { page: Page, ...rest } = this.props;

    return (
      <Route {...rest} render={(props) =>
        <DummyPage>
          <Page {...props} />
        </DummyPage>
      } />
    )
  }
}

DummyRoute.propTypes = {
  page: PropTypes.func.isRequired
};

export default DummyRoute;
