import React from 'react'
import PropTypes from 'prop-types'

import { EditOutlined } from '@ant-design/icons';
import { Button, Skeleton, PageHeader, Tabs, Drawer } from 'antd';

import { GenericPage } from 'app/pages';
import { SimulatorsTab, ContactsTab } from './tabs';

import { CustomerForm } from 'app/forms';

import { Unknown } from 'app/components';

import { API } from 'app/services';
import { BreadcrumbHelper, Notifications } from 'app/utils';

import { Country, Locale } from 'app/enums';

class CustomerPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      fetching: true,
      isEditing: false
    }
  }

  onCustomerUpdate = (customer, onComplete) => {

    this.setState({
      customer: customer
    }, onComplete);
  }

  async componentDidMount() {

    BreadcrumbHelper.shared
      .append("Clients", '/customers')
      .flush();

    try {

      const id = this.props.match.params['customer'];

      // Fetch customer
      let response = await API.get(`/customers/${id}`)

      let customer = response.data

      // Update breadcrumb
      BreadcrumbHelper.shared
        .append(customer.company)
        .flush();

      this.setState({
        fetching: false,
        customer: customer
      });

    } catch(error) {

      // Show error
      Notifications.error(
        "Oops, quelque chose s'est mal passé...",
        "Une erreur est survenue lors de la récupération des informations du client",
        error.message
      )

      this.props.history.push('/customers')
    }
  }

  handleTabChange = (key) => {

    window.location.hash = key;
    this.forceUpdate();
  }

  toggleForm = () => {

    this.setState({
      isEditing: !this.state.isEditing
    });
  }

  handleFormSuccess = (customer) => {

    this.setState({
      customer: customer,
      isEditing: false
    });

    // Update breadcrumb
    BreadcrumbHelper.shared
      .update(1, customer.company)
      .flush();
  }

  render() {

    let currentTab = 'simulators';
    let hash = window.location.hash?.substring(1);

    if (hash) {

      switch (hash) {

        case 'simulators':
        case 'contacts':
          currentTab = hash;

        default:
          window.location.hash = currentTab;
          break
      }
    }

    const { fetching, customer, isEditing } = this.state;

    let company = fetching ? "Chargement..." : customer.company
    let address = fetching ? "" : `${customer.address}, ${customer.zip_code} ${customer.city}`

    let locale = fetching ? "" : Locale.parse(customer.locale)?.name ?? <Unknown help="Valeur inconnue" color="red">{customer.locale}</Unknown>
    let country = fetching ? "" : Country.parse(customer.country)?.name ?? <Unknown help="Valeur inconnue" color="red">{customer.country}</Unknown>

    return (
      <GenericPage>

          {fetching ? (

            <PageHeader title="Chargement...">
              <Skeleton />
            </PageHeader>

          ) : (

            <PageHeader title={company} subTitle={address} ghost={false} extra={[
              locale,
              '-',
              country,
              <Button key={0} type="primary" shape="circle" onClick={this.toggleForm}>
                <EditOutlined />
              </Button>
            ]}>

              <Tabs activeKey={currentTab} onChange={this.handleTabChange} destroyInactiveTabPane>

                {/* Simulators */}
                <Tabs.TabPane tab="Simulateurs" key="simulators">
                  <SimulatorsTab customer={customer} />
                </Tabs.TabPane>

                {/* Contacts */}
                <Tabs.TabPane tab="Contacts" key="contacts">
                  <ContactsTab customer={customer} onCustomerUpdate={this.onCustomerUpdate} />
                </Tabs.TabPane>

              </Tabs>

              {/* Edit form */}
              <Drawer title="Modification du client" placement="right" visible={isEditing} onClose={this.toggleForm} maskClosable={false} >

                {isEditing &&
                  <CustomerForm source={customer} onSuccess={this.handleFormSuccess} makeRequest={(customer, data) => API.put(`/customers/${customer.id}`, data)} />
                }

              </Drawer>

            </PageHeader>
          )}

      </GenericPage>
    );
  }
}

export default CustomerPage;
