import React from 'react'
import PropTypes from 'prop-types'

import { Modal, Spin, Space } from 'antd';

import { Redirect } from "react-router-dom";

import { Auth } from 'app/services';

class LogoutPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      logged: Auth.isLogged()
    };
  }

  async componentDidMount() {

    try {

      //Logout
      await Auth.logout();

    } finally {

      this.setState({
        logged: false
      });
    }
  }

  render() {

    //Not logged, redirect to root
    if (!this.state.logged) {

      return (
        <Redirect to="/" />
      );
    }

    return (
      <Modal title="Veuillez patienter" visible closable={false} centered footer={null}>
        <Space>
          <Spin />
          Déconnexion en cours...
        </Space>
      </Modal>
    )
  }
}

export default LogoutPage;
