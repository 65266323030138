import React from 'react'

import { Timeline } from 'app/components';

import { SimulatorEvent, SimulatorLogbookTag } from 'app/enums';

class TimelineTab extends React.Component {

  render() {

    const { simulator } = this.props;

    return (
      <Timeline source={simulator} origin={`/simulators/${simulator.id}`} events={SimulatorEvent} tags={SimulatorLogbookTag} />
    );
  }
}

export default TimelineTab;
