import React from 'react'
import PropTypes from 'prop-types'

import classnames from 'classnames';

import './style.scoped.css';

class Flex extends React.Component {

  render() {

    const {
      vertical,
      inline,
      space,

      centerV,
      centerH,

      alignLeft,
      alignRight,
      alignTop,
      alignBottom,

      spaceBetween,
      spaceBetweenH,
      spaceBetweenV,

      spaceArround,
      spaceArroundH,
      spaceArroundV,

      wrap,

      className,
      children,

      ...rest
    } = this.props;

    const horizontal = !vertical;

    let classes = classnames(className, 'flex', {
      'inline': inline,

      'vertical': vertical,
      'horizontal': horizontal,

      'center-Z': horizontal ? centerH : centerV,
      'center-v': horizontal ? centerV : centerH,

      'align-left':   alignLeft,
      'align-right':  alignRight,
      'align-top':    alignTop,
      'align-bottom': alignBottom,

      'space-between-h': (spaceBetween || spaceBetweenH),
      'space-between-v': (spaceBetween || spaceBetweenV),

      'wrap': wrap
    })

    return (
      <div className={classes} style={{ gap: space }} {...rest}>
        {children}
      </div>
    );
  }
}

Flex.defaultProps = {
  vertical: false,
  alignLeft: false,
  alignRight: false,
  alignTop: false,
  alignBottom: false,
  centerH: false,
  centerV: false,
  spaceBetween: false,
  spaceBetweenV: false,
  spaceBetweenH: false,
  spaceArround: false,
  spaceArroundV: false,
  spaceArroundH: false,
};

Flex.propTypes = {
  vertical: PropTypes.bool,
  alignLeft: PropTypes.bool,
  alignRight: PropTypes.bool,
  alignTop: PropTypes.bool,
  alignBottom: PropTypes.bool,
  centerH: PropTypes.bool,
  centerV: PropTypes.bool,
  spaceBetween: PropTypes.bool,
  spaceBetweenV: PropTypes.bool,
  spaceBetweenH: PropTypes.bool,
  spaceArround: PropTypes.bool,
  spaceArroundV: PropTypes.bool,
  spaceArroundH: PropTypes.bool,
};

export default Flex;
