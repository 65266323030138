import React from 'react'
import PropTypes from 'prop-types'

import {
  TabletFilled,
  AppleFilled
} from '@ant-design/icons';

import { Statistic } from 'antd';

import { Card } from 'antd';

import { Flex, Discrete } from 'app/components';

import moment from 'moment';

class CardApplication extends React.Component {

  render() {

    const { simulator } = this.props;

    const { app_version, ios_version } = simulator;
    const { last_sync, last_update } = simulator;

    return (
      <Card title="Application" hoverable>

        <Flex spaceBetweenH wrap>
          <Statistic title="Version" value={app_version ?? "–"} prefix={<TabletFilled />} />
          <Statistic title="Version iOS" value={ios_version ?? "–"} prefix={<AppleFilled />} />
        </Flex>

        {(last_update || last_sync) &&
          <Discrete>

            {/* Last app update */}
            {last_update &&
              <Flex spaceBetweenH wrap>
                <span>Installé le</span>
                <span>{moment(last_update).format('DD/MM/YYYY [à] HH:mm:ss')}</span>
              </Flex>
            }

            {/* Last app sync */}
            {last_sync &&
              <Flex spaceBetweenH wrap>
                <span>Synchronisé le</span>
                <span>{moment(last_sync).format('DD/MM/YYYY [à] HH:mm:ss')}</span>
              </Flex>
            }

          </Discrete>
        }

      </Card>
    )
  }
}

export default CardApplication;
