import Enum from './_Enum';

let Universe = {

  FRENCH: {
    code: 'fr_FR',
    name: 'Français',
    direction: 'ltr'
  },

  ENGLISH: {
    code: 'en_EN',
    name: 'Anglais',
    direction: 'ltr'
  },

  DUTCH: {
    code: 'nl_NL',
    name: 'Néerlandais',
    direction: 'ltr'
  }
};

export default Enum(Universe, Universe.FRENCH, (id, values) => {
  return values.find(locale => locale.code === id);
});
