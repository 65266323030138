let Constants = {

  /** Max simulators. */
  MAX_SIMULATORS: 9999,

  /** Max equipments per kind per simulator. */
  MAX_EQUIPMENTS_PER_KIND_PER_SIMULATOR: 99
};

export default Object.seal(Constants);
