class Env {

  // Build
  static BUILD_VERSION = process.env.REACT_APP_VERSION;
  static BUILD_HASH    = process.env.REACT_APP_BUILD_HASH;
  static BUILD_DATE    = process.env.REACT_APP_BUILD_DATE;

  static ENVIRONMENT   = process.env.NODE_ENV;
  static DEPLOY_TARGET = process.env.REACT_APP_DEPLOY_TARGET;

  // API
  static API_URL = process.env.REACT_APP_API_URL;

  // Mantis
  static MANTIS_URL        = "https://37.58.201.35:18298" //process.env.MANTIS_URL;
  static MANTIS_PROJECT_ID = 1                            //process.env.MANTIS_PROJECT_ID;
}

export { Env }
