import React from 'react'
import PropTypes from 'prop-types'

import { withRouter } from 'react-router-dom';

import classnames from 'classnames';
import moment from 'moment';

import { debounce } from 'lodash-es';

import {
  CheckOutlined,
  CloseOutlined,
  WarningOutlined
} from '@ant-design/icons';

import { Table, Drawer, Input, Button, Tag, Tooltip, Typography, Space } from 'antd';

const { Column } = Table;
const { Search } = Input;
const { Paragraph } = Typography;

import { Flex, Unknown, Pin, Discrete, Empty, FirmwareVersion } from 'app/components';
import { EquipmentForm } from 'app/forms';

import { EquipmentKind, EquipmentStatus, BoardStatus, Channel } from 'app/enums';

import { Arrays, Compare, Notifications, Functions } from 'app/utils';

import { API } from 'app/services';

import './style.scoped.css';

class EquipmentsTab extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      isFetching: true,
      equipments: [],
      search: '',
      criteria: '',
      isFormOpen: false
    };
  }

  componentDidMount = async () => {

    const { simulator } = this.props;

    try {

      let response = await API.get(`/simulators/${simulator.id}/equipments`);

      this.setState({
        equipments: response.data,
        isFetching: false
      })

    } catch(error) {

      // Show error
      Notifications.error(
        "Oops, quelque chose s'est mal passé...",
        "Une erreur est survenue lors de la récupération des équipements",
        error.message
      )

    }
  }

  handleSearch = (e) => {

    const { value } = e.target;

    this.setState({
      search: value
    });

    this.applySearch(value)
  }

  applySearch = debounce((value) => {

    this.setState({
      criteria: value.toLowerCase()
    });
  }, 250);

  handleClick = (equipment) => {
    this.props.history.push(`/equipments/${equipment.serial}`);
  }

  toggleForm = () => {

    this.setState({
      isFormOpen: !this.state.isFormOpen
    });
  }

  handleFormSuccess = (equipment) => {

    const { simulator, onSimulatorUpdate } = this.props;

    this.setState({
      equipments: [
        ...this.state.equipments,
        equipment
      ]
    }, () => {

      this.toggleForm();

      // Update
      onSimulatorUpdate({
        ...simulator,
        equipments: [
          ...simulator.equipments,
          equipment.serial
        ]
      })

    });

  }

  render() {

    const { simulator } = this.props;
    const { isFetching, search, criteria, isFormOpen } = this.state;

    const all = this.state.equipments;
    const visible = Arrays.filterByCriteria(all, criteria, (equipment) => {

      const { board } = equipment;
      let boardData = [];

      if(board) {

        boardData = [
          Functions.breakUp(board.ble_mac_address, 2, ':'),
          board.batch_number,
          moment(board.production_date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
          BoardStatus.parse(board.status)?.name,
          board.firmware
        ]
      }

      return [
        equipment.serial,
        EquipmentKind.parse(equipment.kind)?.name,
        EquipmentStatus.parse(equipment.status)?.name,
        ...boardData
      ]
    })

    return (

      <Flex vertical space={16} className="equipments">

        <Flex spaceBetween space={8}>

          <Search placeholder="Rechercher" allowClear onChange={this.handleSearch} value={search} />

          <Button type="primary" onClick={this.toggleForm}>
            Nouveau
          </Button>

        </Flex>

        <Table bordered
          loading={isFetching}
          dataSource={visible}
          rowKey={equipment => equipment.serial}
          onRow={(equipment, rowIndex) => {
            return {
              onClick: () => this.handleClick(equipment)
            };
          }}
          rowClassName={(equipment, index) => {

            const kind = EquipmentKind.parse(equipment.kind)

            return classnames("row", {
              'no-board': kind?.hasBoard && !equipment.board,
              'disabled': !equipment.enabled
            });
          }}
          pagination={{ position: ['bottomCenter'] }}
        >

          {/* ID */}
          {this.column_ID(all, visible)}

          {/* Type */}
          {this.column_kind(all, visible)}

          {/* Board */}
          {this.column_board(all, visible)}

          {/* Board firmware */}
          {this.column_boardFirmware(all, visible)}

          {/* Status */}
          {this.column_status(all, visible)}

          {/* Enabled */}
          {this.column_enabled(all, visible)}

        </Table>

        {/* Modal */}
        <Drawer title="Nouvel équipement" placement="right" visible={isFormOpen} onClose={this.toggleForm} maskClosable={false} >

          {isFormOpen &&
            <EquipmentForm onSuccess={this.handleFormSuccess} makeRequest={(_, data) => API.post(`/simulators/${simulator.id}/equipments`, data)} />
          }

        </Drawer>

      </Flex>

    )
  }

  // Columns

  column_ID = (all, visible) => {

    const { simulator } = this.props;

    let sorter = (a, b) => Compare.string(Functions.equipmentSerial(simulator.id, a.kind, a.identifier), Functions.equipmentSerial(simulator.id, b.kind, b.identifier));

    return (
      <Column
        title="Numéro de série"
        dataIndex="serial"
        key="serial"
        sorter={sorter}
        render={(serial) => {

          return (
            <Tag>
              {serial}
            </Tag>
          );
        }}
      />
    )
  }

  column_kind = (all, visible) => {

    let allKinds = visible
      .map((equipment) => EquipmentKind.parse(equipment.kind))
      .filter(Boolean)
      .unique()

    let filters = allKinds.map((kind) => {
      return {
        text: kind.name,
        value: kind.id
      }
    })

    let onFilter = (filter, equipment) => {
      return equipment.kind === filter;
    };

    let sorter = (a, b) => Compare.string(a.kind, b.kind);

    return (
      <Column
        title="Type"
        dataIndex="kind"
        key="kind"
        filters={filters}
        onFilter={onFilter}
        sorter={sorter}
        render={(rawKind) => {

          const kind = EquipmentKind.parse(rawKind);

          return kind?.name ?? <Unknown>{rawKind}</Unknown>
        }}
      />
    )
  }

  column_board = (all, visible) => {

    let filters = [
      {
        text: 'Aucune',
        value: -1
      }
    ];

    let allBatchNumbers = visible
      .map((equipment) => equipment.board?.batch_number)
      .filter(Boolean)
      .unique()
      .sort(Compare.string)

    if(allBatchNumbers.length > 0) {

      filters.push({
        text: 'N° de lot',
        children: allBatchNumbers.map((batchNumber) => {
          return {
            text: batchNumber,
            value: batchNumber
          }
        })
      })
    }

    let allStatus = BoardStatus.values()

    filters.push({
      text: 'Statut',
      children: allStatus.map((status) => {
        return {
          text: <Pin color={status.color}>{status.name}</Pin>,
          value: status.id
        }
      })
    })

    let onFilter = (filter, equipment) => {

      const { board } = equipment;

      switch(filter) {

        case -1:
          return !board;

        default:

          return board?.batch_number === filter || board?.status === filter
      }
    }

    let sorter = (a, b) => Compare.string(a.board?.ble_mac_address, b.board?.ble_mac_address);

    return (
      <Column
        title="Carte éléctronique"
        dataIndex="board"
        key="board"
        filterMode="tree"
        filters={filters}
        onFilter={onFilter}
        sorter={sorter}
        render={(board, equipment) => {

          if(board) {

            const status = BoardStatus.parse(board.status);

            return (
              <Flex vertical>

                {/* BLE Mac address */}
                <Paragraph copyable={{ text: Functions.breakUp(board.ble_mac_address, 2, ':') }} style={{ margin: 0 }}>
                  {Functions.breakUp(board.ble_mac_address, 2, ':')}
                </Paragraph>

                <Discrete>
                  <Space>

                    {/* Batch number */}
                    <Tooltip title="Numéro de lot" placement="bottom">
                      {board.batch_number}
                    </Tooltip>

                    {/* Production date */}
                    <Tooltip title="Date de production" placement="bottom">
                      {moment(board.production_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                    </Tooltip>

                    {/* Status */}
                    {status ? (
                      <Pin color={status.color}>
                        <Tooltip title="Statut" placement="bottom">
                          {status.name}
                        </Tooltip>
                      </Pin>
                    ) : (
                      <Unknown>
                        {board.status}
                      </Unknown>
                    )}

                  </Space>
                </Discrete>

              </Flex>
            )

          } else {

            const kind = EquipmentKind.parse(equipment.kind)

            if (kind.hasBoard) {

              return (
                <Tooltip title="Aucune carte associée">
                  <WarningOutlined style={{ color: "orange" }} />
                </Tooltip>
              )

            } else {

              return (
                <Empty />
              )
            }
          }
        }}
      />
    )
  }

  column_boardFirmware = (all, visible) => {

    let allFirmwareVersions = visible
      .map((equipment) => equipment.board?.firmware?.version)
      .filter(Boolean)
      .unique()

    let filters = [
      {
        text: 'Aucun',
        value: -1
      },
      {
        text: 'Version',
        children: allFirmwareVersions.map((firmware) => {
          return {
            text: firmware,
            value: firmware
          }
        })
      },
      {
        text: 'Canal',
        children: Channel.values().map((channel) => {
          return {
            text: <Pin color={channel.color}>{channel.name}</Pin>,
            value: channel.id
          }
        })
      }
    ]

    let onFilter = (filter, equipment) => {

      const firmware = equipment.board?.firmware;

      //No firmware
      if(filter === -1) {
        return !firmware;
      }

      //Channel
      if(Channel.parse(filter)) {
        return filter === firmware?.channel;
      }

      //Version
      return filter === firmware?.version;
    };

    let sorter = (a, b) => Compare.string(a.board?.firmware?.version, b.board?.firmware?.version);

    return (
      <Column
        title="Micrologiciel"
        dataIndex="board"
        key="firmware"
        filterMode="tree"
        filters={filters}
        onFilter={onFilter}
        sorter={sorter}
        render={(board) => {

          return (
            <>
              {board?.firmware ? (
                <FirmwareVersion {...board.firmware} />
              ) : (
                <Empty />
              )}
            </>
          )
        }}
      />
    )
  }

  column_status = (all, visible) => {

    let allStatus = EquipmentStatus.values()

    let filters = allStatus.map((status) => {
      return {
        text: <Pin color={status.color}>{status.name}</Pin>,
        value: status.id
      }
    })

    let onFilter = (filter, board) => {
      return board.status === filter
    }

    let sorter = (a, b) => Compare.string(a.status, b.status);

    return (
      <Column
        title="Statut"
        dataIndex="status"
        key="status"
        filterMode="tree"
        filters={filters}
        onFilter={onFilter}
        sorter={sorter}
        render={(rawStatus) => {

          const status = EquipmentStatus.parse(rawStatus);

          if (status) {
            return (
              <Pin color={status.color}>
                {status.name}
              </Pin>
            )
          } else {
            return (
              <Unknown>{rawStatus}</Unknown>
            )
          }
        }}
      />
    )
  }

  column_enabled = (all, visible) => {

    let sorter = (a, b) => Compare.boolean(a.enabled, b.enabled);

    let filters = [
      {
        text: <><CheckOutlined style={{ color: "green" }} /> Activé</>,
        value: 'enabled'
      },
      {
        text: <><CloseOutlined style={{ color: "red" }} /> Désactivé</>,
        value: 'disabled'
      }
    ]

    let onFilter = (filter, equipment) => {

      const { enabled } = equipment;

      switch(filter) {

        case 'enabled':
          return enabled

        case 'disabled':
          return !enabled
      }
    };

    return (
      <Column
        title="Disponible"
        dataIndex="enabled"
        key="enabled"
        filters={filters}
        onFilter={onFilter}
        sorter={sorter}
        render={(enabled) => enabled ? <CheckOutlined style={{ color: "green" }} /> : <CloseOutlined style={{ color: "red" }} />}
      />
    )
  }

}

export default withRouter(EquipmentsTab);
