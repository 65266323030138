import { Typeof } from 'app/utils';

class Compare {

  //-- Boolean

  static boolean(a, b) {
    return Compare.number(Number(a), Number(b));
  }

  static booleanInverse(a, b) {
    return Compare.boolean(b, a)
  }

  //-- Number

  static number(a, b) {

    let aIsValid = Typeof.number(a)
    let bIsValid = Typeof.number(b)

    if(aIsValid && bIsValid) {
      return a - b
    } else {

      if (aIsValid) {
        return -1
      } else {
        return 1
      }
    }
  }

  static numberInverse(a, b) {
    return Compare.number(b, a)
  }

  //-- String

  static string(a, b) {

    let aIsValid = Typeof.string(a)
    let bIsValid = Typeof.string(b)

    if(aIsValid && bIsValid) {
      return a.localeCompare(b)
    } else {

      if(aIsValid) {
        return -1
      } else {
        return 1
      }
    }
  }

  static stringInverse(a, b) {
    return Compare.string(b, a)
  }

  //-- Version

  static version(a, b) {

    let aIsValid = Typeof.string(a)
    let bIsValid = Typeof.string(b)

    if (aIsValid && bIsValid) {
      return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' })
    } else {

      if (aIsValid) {
        return -1
      } else {
        return 1
      }
    }
  }

  static versionInverse(a, b) {
    return Compare.version(b, a)
  }
}

export default Compare;
