import React from 'react'
import PropTypes from 'prop-types'

import { withRouter } from 'react-router-dom';

import { Result, Button, Modal } from 'antd';

class UnauthorizedPage extends React.Component {

  handleBack = () => {
    this.props.history.goBack();
  }

  render() {

    return (
      <Modal visible closable={false} centered footer={false}>
        <Result
          status="403"
          title="Resource sécurisée"
          subTitle="Vous n'avez pas les autorisations nécessaires pour accéder à la page demandée."
          extra={
            <Button type="primary" onClick={this.handleBack}>Retour</Button>
          }
        />
      </Modal>
    );
  }
}

export default withRouter(UnauthorizedPage);
