import React from 'react'
import PropTypes from 'prop-types'

import { Form, Button, DatePicker, InputNumber, Col, Row } from 'antd'

import { Empty } from 'app/components';

import { Notifications, Validation } from 'app/utils';

import moment from 'moment';

import './style.scoped.css';

class WarrantyForm extends React.Component {

  constructor(props) {
    super(props)

    // Default values
    let now = moment();
    let duration = 12;

    let fields = [
      { name: ['since'], value: now },
      { name: ['duration'], value: duration },
    ]

    this.state = {
      pending: false,
      fields: fields,
      end: moment(now).add(duration, 'months')
    }
  }

  handleSubmit = async (data) => {

    const { makeRequest } = this.props;

    data.since = data.since.format('YYYY-MM-DD');

    this.setState({
      pending: true
    });

    let newState = {
      pending: false
    };

    var onComplete;

    try {

      let response = await makeRequest(data);

      //Notify success
      onComplete = () => {
        this.props.onSuccess(response.data)
      }

    } catch (error) {

      try {

        // Validation
        newState.fields = Validation.parseValidationsErrors(this.state.fields, error);

      } catch (error) {

        // Unhandled error
        Notifications.error(
          "Oops, quelque chose s'est mal passé...",
          "Une erreur est survenue lors de la mise à jour des informations de la garantie",
          error.message
        )
      }

    } finally {
      this.setState(newState, onComplete);
    }
  }

  handleFieldsChange = (_, fields) => {

    let since = fields.find((field) => field.name[0] === 'since').value
    let duration = fields.find((field) => field.name[0] === 'duration').value

    let end;

    if (since) {
      end = moment(since).add(duration, 'months');
    }

    this.setState({
      fields: fields,
      end: end
    });
  }

  render() {

    const { fields, pending, end } = this.state;

    return (
      <Form
        name="warranty-form"
        fields={fields}
        layout="vertical"
        onFieldsChange={this.handleFieldsChange}
        disabled={pending}
        onFinish={this.handleSubmit}
      >

        <Row gutter={10}>

          <Col span={12}>

            {/* Since */}
            <Form.Item name="since" label="A partir du" required>
              <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} />
            </Form.Item>

          </Col>

          <Col span={12}>

            {/* End */}
            <Form.Item label="Jusqu'au" className="end-date" required>
              {end ? (
                <span>{end.format('DD/MM/YYYY')}</span>
              ) : (
                <Empty />
              )}
            </Form.Item>

          </Col>

        </Row>

        {/* Duration */}
        <Form.Item name="duration" label="Durée" required>
          <InputNumber addonAfter="mois" min={1} style={{ width: '100%' }} />
        </Form.Item>

        <Button form="warranty-form" type="primary" htmlType="submit">
          Valider
        </Button>

      </Form>
    )
  }
}

export default WarrantyForm;
