import React from 'react'
import PropTypes from 'prop-types'

import { debounce } from 'lodash-es';

import {
  GlobalOutlined
} from '@ant-design/icons';

import { GenericPage } from 'app/pages';

import { Link } from 'react-router-dom';

import { Space, Input, Button, Table, Avatar, Drawer } from 'antd';

const { Column, ColumnGroup } = Table;
const { Search } = Input;

import { CustomerForm } from 'app/forms';

import { Flex, Unknown } from 'app/components';

import { API } from 'app/services';
import { BreadcrumbHelper, Compare, Functions, Arrays } from 'app/utils';

import { Country, Locale } from 'app/enums';

class CustomersPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      customers: [],
      search: '',
      criteria: '',
      isFormOpen: false
    };
  }

  async componentDidMount() {

    BreadcrumbHelper.shared
      .append("Clients")
      .flush();

    const params = new Map(this.props.location.search.slice(1).split('&').map(kv => kv.split('=')))

    let search = '';

    if (params.has('search')) {
      search = decodeURIComponent(params.get('search')).trim();
    }

    try {

      //Logout
      let response = await API.get('/customers');
      let customers = response.data;

      this.setState({
        customers: customers,
        loading: false,
        search: search,
        criteria: search
      });

    } catch {

    }
  }

  handleSearch = (e) => {

    const { value } = e.target;

    this.setState({
      search: value
    });

    this.applySearch(value)
  }

  applySearch = debounce((value) => {

    this.setState({
      criteria: value.toLowerCase()
    });
  }, 250);

  handleClick = (customer) => {
    this.props.history.push(`/customers/${customer.id}`);
  }

  toggleForm = () => {

    this.setState({
      isFormOpen: !this.state.isFormOpen
    });
  }

  handleFormSuccess = (customer) => {

    const { customers } = this.state;

    this.setState({
      customers: [
        ...customers,
        customer
      ]
    }, this.toggleForm);
  }

  render() {

    const { loading, search, criteria, isFormOpen } = this.state;

    const all = this.state.customers;
    const visible = Arrays.filterByCriteria(all, criteria, (customer) => {

      return [
        `#${customer.id}`,
        customer.company,
        customer.address,
        customer.zip_code,
        customer.city,
        Country.parse(customer.country)?.name,
        Locale.parse(customer.locale)?.name
      ]
    })

    return (
      <GenericPage>

        <Flex vertical space={16}>

          <Flex spaceBetween space={8}>

            <Search placeholder="Rechercher" allowClear onChange={this.handleSearch} value={search} />

            <Button type="primary" onClick={this.toggleForm}>
              Nouveau
            </Button>

          </Flex>

          <Table bordered
            loading={loading}
            dataSource={visible}
            rowKey={customer => customer.id}
            onRow={(customer, rowIndex) => {
              return {
                onClick: () => this.handleClick(customer)
              };
            }}
            pagination={{ position: [ 'bottomCenter' ]}}
          >

            {/* ID */}
            {this.column_ID(all, visible)}

            {/* Company */}
            {this.column_company(all, visible)}

            {/* Location */}
            <ColumnGroup title="Localisation">

              {/* Address */}
              {this.column_address(all, visible)}

              {/* Country */}
              {this.column_country(all, visible)}

            </ColumnGroup>

            {/* Locale */}
            {this.column_locale(all, visible)}

            {/* Simulators */}
            {this.column_simulators(all, visible)}

          </Table>

          {/* Modal */}
          <Drawer title="Nouveau client" placement="right" visible={isFormOpen} onClose={this.toggleForm} maskClosable={false} >

            {isFormOpen &&
              <CustomerForm onSuccess={this.handleFormSuccess} makeRequest={(_, data) => API.post(`/customers`, data)} />
            }

          </Drawer>

        </Flex>

      </GenericPage>
    );
  }

  /* Columns */

  column_ID = (all, visible) => {

    return (
      <Column
        title="#"
        dataIndex="id"
        key="id"
        sorter={(a, b) => Compare.number(a.id, b.id)}
        render={(id) => {
          return (
            <Avatar>{id}</Avatar>
          )
        }}
      />
    )
  }

  column_company = (all, visible) => {

    let allCompanies = visible
      .map((customer) => customer.company)
      .unique();

    let filters = allCompanies
      .map((company) => {

        return {
          text: company,
          value: company
        }
      })

    let onFilter = (filter, customer) => customer.company === filter;

    let sorter = (a, b) => Compare.string(a.company, b.company);

    return (
      <Column
        title="Société"
        dataIndex="company"
        key="company"
        filters={filters}
        onFilter={onFilter}
        sorter={sorter}
      />
    )
  }

  column_address = (all, visible) => {

    let sorter = (a, b) => Compare.string(`${a.address}, ${a.zip_code} ${a.city}`, `${b.address}, ${b.zip_code} ${b.city}`);

    return (
      <Column
        title="Adresse"
        key="address"
        sorter={sorter}
        render={(_, customer) => {
          return (
            <Space>
              <Link to={{ pathname: encodeURI(`https://www.google.com/maps/search/?api=1&query=${customer.address}, ${customer.zip_code} ${customer.city}`) }} target="_blank" onClick={(event) => event.stopPropagation()}>
                <GlobalOutlined />
              </Link>
              <span>
                {customer.address},<br /> {customer.zip_code} {customer.city}
              </span>
            </Space>
          )
        }}
      />
    )
  }

  column_country = (all, visible) => {

    let filters = Country.values()
      .map((locale) => {
        return {
          text: locale.name,
          value: locale.code
        }
      });

    let onFilter = (filter, customer) => customer.locale === filter;

    let sorter = (a, b) => Compare.string(a.locale, b.locale);

    return (
      <Column
        title="Pays"
        dataIndex="country"
        key="country"
        filters={filters}
        onFilter={onFilter}
        sorter={sorter}
        render={(_, customer) => {
          return Country.parse(customer.country)?.name ?? <Unknown help="Valeur inconnue" color="red">{customer.country}</Unknown>
        }}
      />
    )
  }

  column_locale = (all, visible) => {

    let filters = Locale.values()
      .map((locale) => {
        return {
          text: locale.name,
          value: locale.code
        }
      });

    let onFilter = (filter, customer) => customer.locale === filter;

    let sorter = (a, b) => Compare.string(a.locale, b.locale);

    return (
      <Column
        title="Langue"
        dataIndex="locale"
        key="locale"
        filters={filters}
        onFilter={onFilter}
        sorter={sorter}
        render={(_, customer) => {
          return Locale.parse(customer.locale)?.name ?? <Unknown help="Valeur inconnue" color="red">{customer.locale}</Unknown>
        }}
      />
    )
  }

  column_simulators = (all, visible) => {

    return (
      <Column
        title="Simulateurs"
        key="simulators"
        sorter={(a, b) => Compare.number(a.simulators.length, b.simulators.length)}
        render={(_, customer) => {
          return customer.simulators.length
        }}
      />
    )
  }
}

export default CustomersPage;
