import React from 'react'

import { Link } from "react-router-dom";

import {
  UserOutlined,
  MailOutlined
} from '@ant-design/icons';

import { Modal, Form, Input, Button, Result } from 'antd';

import { Flex } from 'app/components';

import { Notifications, Validation } from 'app/utils';
import { API } from 'app/services';

class PasswordRecoveryPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      fields: [
        { name: ['email'] }
      ],
      pending: false,
      success: false
    };
  }

  handleFinish = async (data) => {

    this.setState({
      pending: true
    });

    let newState = {
      pending: false
    };

    try {

      //Reset Password
      await API.post('/users/password-reset', { email: data.email });

      // Send Email
      newState.success = true;

    } catch (error) {

      try {

        // Validation
        newState.fields = Validation.parseValidationsErrors(this.state.fields, error);

      } catch (error) {

        // Unhandled error
        Notifications.error(
          "Oops, quelque chose s'est mal passé...",
          "Une erreur est survenue lors de la demande de réinitialisation de votre mot de passe",
          error.message
        )
      }

    } finally {
      this.setState(newState);
    }
  }

  handleChange = (_, fields) => {

    this.setState({
      fields: fields
    });
  }

  renderSuccess() {

    return (
      <Modal title="Réinitialisation de mot de passe" visible closable={false} centered footer={false}>

        <Result
          icon={<MailOutlined />}
          status="success"
          title="Vérifiez votre boîte de récéption"
          subTitle="Un courriel vous a été envoyé avec les instructions pour réinitialiser votre mot de passe"
          extra={
            <>
              <Button href="/login" type="primary" key={"confirmation"}>
                Fermer
              </Button>
            </>
          }
        />

      </Modal>
    )
  }

  renderForm() {

    const { fields, pending } = this.state;

    return (
      <Modal title="Réinitialisation de mot de passe" visible closable={false} centered footer={

        <Flex spaceBetweenH>

          {/* Back */}
          <Link to="/login">Retour</Link>

          {/* Validate */}
          <Button form="login" type="primary" htmlType="submit" disabled={pending}>
            Valider
          </Button>
        </Flex>

      }>

        <Form disabled={pending} name="login" onFinish={this.handleFinish} fields={fields} onFieldsChange={this.handleChange}>

          {/* Email address */}
          <Form.Item name="email" required>

            <Input prefix={
              <UserOutlined className="site-form-item-icon" />
            } placeholder="Adresse e-mail" />

          </Form.Item>

        </Form>

      </Modal>
    )
  }

  render() {

    const { pending, success } = this.state;

    if(success) {
      return this.renderSuccess();
    }

    return this.renderForm();
  }
}

export default PasswordRecoveryPage;
