import React from 'react'

import { Link } from 'react-router-dom';

import { GenericPage } from 'app/pages';

import {
  FieldTimeOutlined,
  EditOutlined,
  CheckOutlined,
  CloseOutlined
} from '@ant-design/icons';

import { Skeleton, PageHeader, Tabs, Button, Tag, Drawer, Alert, Space } from 'antd';

import { Flex, Discrete, Pin } from 'app/components';
import { EquipmentForm } from 'app/forms';

import {
  InformationsTab,
  TimelineTab
} from './tabs';

import { EquipmentKind, EquipmentStatus } from 'app/enums';

import { API } from 'app/services';
import { BreadcrumbHelper, Functions, Notifications } from 'app/utils';

import './style.scoped.css';

class EquipmentPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      fetching: true,
      isFormOpen: false
    }
  }

  async componentDidMount() {

    BreadcrumbHelper.shared
      .append("Équipements", '/equipments')
      .flush();

    try {

      const id = this.props.match.params['equipment'];

      // Fetch equipment
      let response = await API.get(`/equipments/${id}`)

      let equipment = response.data

      BreadcrumbHelper.shared
        .append(equipment.serial)
        .flush();

      this.setState({
        fetching: false,
        equipment: equipment
      });

    } catch(error) {

      Notifications.error(
        "Oops, quelque chose s'est mal passé...",
        "Une erreur est survenue lors de la récupération des informations de l'équipement",
        error.message
      )

      this.props.history.push('/equipments')
    }
  }

  handleTabChange = (key) => {

    window.location.hash = key;
    this.forceUpdate();
  }

  toggleForm = () => {

    this.setState({
      isFormOpen: !this.state.isFormOpen
    });
  }

  handleFormSuccess = (equipment) => {

    this.setState({
      equipment: equipment
    }, this.toggleForm);
  }

  onEquipmentUpdate = (equipment, onComplete) => {

    this.setState({
      equipment: equipment
    }, onComplete);
  }


  renderEquipment() {

    const { equipment, isFormOpen } = this.state;

    const kind = EquipmentKind.parse(equipment.kind);
    const status = EquipmentStatus.parse(equipment.status);

    let currentTab = kind?.hasBoard ? 'informations' : 'timeline';
    let hash = window.location.hash?.substring(1);

    if (hash) {

      switch (hash) {

        case 'informations':
        case 'timeline':
          currentTab = hash;

        default:
          window.location.hash = currentTab;
          break
      }
    }

    let serial = equipment.serial.split('-');

    const title = (
      <Flex vertical>

        {/* Serial */}
        <span>
          <Link to={`/simulators/${equipment.simulator}`}>{serial[0]}</Link>
          -
          {serial[1]}
        </span>

        {/* Meta */}
        <Discrete>
          <Space>

            <Tag>
              {kind.name}
            </Tag>

            {/* Status */}
            {status ? (
              <Pin color={status.color}>
                {status.name}
              </Pin>
            ) : (
              <Unknown>
                {equipment.status}
              </Unknown>
            )}

            {/* Enabled */}
            <Flex space={4} centerV>
              {equipment.enabled ? (
                <>
                  <CheckOutlined style={{ color: "green" }} />
                  Disponible
                </>
              ) : (
                <>
                  <CloseOutlined style={{ color: "red" }} />
                  Indisponible
                </>
              )}
            </Flex>

          </Space>
        </Discrete>

      </Flex>
    )

    const extra = (
      <>
        {/* Edit */}
        <Button key={0} type="primary" shape="circle" onClick={this.toggleForm}>
          <EditOutlined />
        </Button>
      </>
    )

    return (
      <PageHeader title={title} extra={extra} ghost={false}>

        {/* Disabled */}
        {!equipment.enabled &&
          <Alert
            message="Attention"
            description={
              <Flex vertical space={4}>
                <span>
                  Cet équipement est <Tag><CloseOutlined style={{ color: "red" }} /> Indisponible</Tag>il ne pourra pas être utilisé lors des séances de simulations
                </span>
              </Flex>
            }
            type="warning"
            closable
            showIcon
          />
        }

        <Tabs activeKey={currentTab} onChange={this.handleTabChange} destroyInactiveTabPane>

          {/* Board */}
          {kind?.hasBoard &&
            <Tabs.TabPane tab="Informations" key="informations" >
              <InformationsTab equipment={equipment} onEquipmentUpdate={this.onEquipmentUpdate} />
            </Tabs.TabPane>
          }

          {/* Timeline */}
          <Tabs.TabPane tab={
            <span>
              Chronologie <FieldTimeOutlined className="inline" />
            </span>
          } key="timeline" >
            <TimelineTab equipment={equipment} />
          </Tabs.TabPane>

        </Tabs>

        {/* Edit form */}
        <Drawer title="Modification de l'équipement" placement="right" visible={isFormOpen} onClose={this.toggleForm} maskClosable={false} >

          {isFormOpen &&
            <EquipmentForm source={equipment} onSuccess={this.handleFormSuccess} makeRequest={(equipment, data) => API.put(`/equipments/${equipment.serial}`, data)} />
          }

        </Drawer>

      </PageHeader>
    )
  }

  render() {

    const { fetching, equipment } = this.state;

    return (
      <GenericPage>

        {fetching ? (

          <PageHeader title="Chargement...">
            <Skeleton />
          </PageHeader>

        ) : (

          this.renderEquipment()
        )}

      </GenericPage>
    );
  }
}

export default EquipmentPage;
