import { Typeof } from 'app/utils';

Object.defineProperty(Array.prototype, 'unique', {
  value: function () { return Arrays.unique(this) }
});

class Arrays {

  static unique(array) {

    return array.filter((value, index) => {
      return array.indexOf(value) === index;
    });
  }

  static filterByCriteria(array, criteria, getFields) {

    //Not an array
    if(!Typeof.array(array) || array.length == 0) {
      return [];
    }

    criteria = criteria?.trim()?.toLowerCase()

    //No criteria
    if(!Typeof.string(criteria) || criteria.length == 0) {
      return array;
    }

    return array.filter((value) => {

      //Get fields
      let fields = getFields(value).filter(Boolean);

      //Filter by fields
      return fields.some((field) => `${field}`.toLowerCase().includes(criteria));
    });
  }
}

export default Arrays;
