import React from 'react'
import PropTypes from 'prop-types'

//TOOD: Logo, animation etc...
class Splash extends React.Component {

  render() {

    return (
      ''
    );
  }
}

export default Splash;
