import Enum from './_Enum';

import { Link } from 'react-router-dom';

import {
  PlusOutlined,
  EditOutlined,
  LockOutlined,
  UnlockOutlined,
  LinkOutlined,
  CloseOutlined,
  CloudSyncOutlined,
  AppleFilled,
  TabletFilled
} from '@ant-design/icons';

import { Tag, Space } from 'antd';

import { Discrete } from 'app/components';

import { Functions } from 'app/utils';

let Universe = {

  ASSOCIATE: {
    type: 'ASSOCIATE',
    name: 'Association',
    category: 'Client',
    message: (payload) => {

      return (
        <>
          Le simulateur a été associé au client <Link to={`/customers/${payload.customer}`}>{payload.company}</Link>
        </>
      )
    },
    icon: <LinkOutlined />
  },

  DISSOCIATE: {
    type: 'DISSOCIATE',
    name: 'Dissociation',
    category: 'Client',
    message: (payload) => {

      return (
        <Space>
          Le simulateur a été dissocié au client <Link to={`/customers/${payload.customer}`}>{payload.company}</Link>

          {!payload.manual &&
            <Discrete>
              Automatique
            </Discrete>
          }
        </Space>
      )
    },
    icon: <LinkOutlined />
  },

  WARRANTY_CHANGE: {
    type: 'WARRANTY_CHANGE',
    name: 'Changement de garantie',
    category: 'Garantie',
    message: (payload) => "La garantie a été modifiée",
    icon: <EditOutlined />
  },

  WARRANTY_VOID: {
    type: 'WARRANTY_VOID',
    name: 'Interruption de garantie',
    category: 'Garantie',
    message: (payload) => "La garantie a été interrompue",
    icon: <CloseOutlined />
  },

  APP_ACTIVATE: {
    type: 'APP_ACTIVATE',
    name: 'Activation',
    category: 'Application',
    message: (payload) => {

      return (
        <Space>
          L'application a été activée avec le code <Tag>{Functions.breakUp(payload.code, 4, ' – ')}</Tag>
        </Space>
      )
    },
    icon: <LockOutlined />
  },

  APP_DESACTIVATE: {
    type: 'APP_DESACTIVATE',
    name: 'Désactivation',
    category: 'Application',
    message: (payload) => "L'application a été désactivée",
    icon: <UnlockOutlined />
  },

  APP_SYNC: {
    type: 'APP_SYNC',
    name: 'Synchronization',
    category: 'Application',
    message: (payload) => "L'application a été synchronisée",
    icon: <CloudSyncOutlined />
  },

  APP_UPDATE: {
    type: 'APP_UPDATE',
    name: 'Mise à jour application',
    category: 'Application',
    message: (payload) => {

      return (
        <Space>
          L'application a été mis à jour en version <b>{payload.version}</b>
        </Space>
      )
    },
    icon: <TabletFilled />
  },

  IOS_UPDATE: {
    type: 'IOS_UPDATE',
    name: 'Mise à jour iOS',
    category: 'Application',
    message: (payload) => {

      return (
        <Space>
          iOS a été mis à jour en version <b>{payload.version}</b>
        </Space>
      )
    },
    icon: <AppleFilled />
  },

  //-- Equipments

  EQUIPMENT_ADD: {
    type: 'EQUIPMENT_ADD',
    name: "Ajout d'un équipement",
    category: 'Équipement',
    message: (payload, simulator) => {

      return (
        <Space>
          L'équipement
          <Link to={`/equipments/${payload.serial}`}>
            <Tag>
              {payload.serial}
            </Tag>
          </Link>a été ajouté
        </Space>
      )
    },
    icon: <PlusOutlined />
  },

  EQUIPMENT_UPDATE: {
    type: 'EQUIPMENT_UPDATE',
    name: "Modification d'un équipement",
    category: 'Équipement',
    message: (payload, simulator) => {

      return (
        <Space>
          L'équipement <Link to={`/equipments/${payload.serial}`}>
            <Tag>
              {payload.serial}
            </Tag>
          </Link> a été modifié
        </Space>
      )
    },
    icon: <EditOutlined />
  }
};

export default Enum(Universe, undefined, (type, values) => {
  return values.find((value) => value.type === type);
});
