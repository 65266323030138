import React from 'react'
import PropTypes from 'prop-types'

import { debounce, forIn } from 'lodash-es';

import { GenericPage } from 'app/pages'

import moment from 'moment';

import {
  TabletFilled,
  TagOutlined
} from '@ant-design/icons';

import { Space, Input, Button, Table, Avatar, Drawer, Tag, Badge } from 'antd';

const { Column, ColumnGroup } = Table;
const { Search } = Input;

import { Flex, Empty, Unknown, Pin, WarrantySummary } from 'app/components';

import { Conditioning, EquipmentKind, WarrantyStatus } from 'app/enums';

import { SimulatorForm } from 'app/forms';

import { API } from 'app/services';
import { BreadcrumbHelper, Compare, Arrays, Typeof } from 'app/utils';

class SimulatorsPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      simulators: [],
      search: '',
      criteria: '',
      isFormOpen: false
    };
  }

  async componentDidMount() {

    BreadcrumbHelper.shared
      .append("Simulateurs")
      .flush();

    const params = new Map(this.props.location.search.slice(1).split('&').map(kv => kv.split('=')))

    let search = '';

    if (params.has('search')) {
      search = decodeURIComponent(params.get('search')).trim();
    }

    try {

      //Logout
      let response = await API.get('/simulators');
      let simulators = response.data;

      this.setState({
        simulators: simulators,
        loading: false,
        search: search,
        criteria: search
      });

    } catch {

    }
  }

  handleSearch = (e) => {

    const { value } = e.target;

    this.setState({
      search: value
    });

    this.applySearch(value)
  }

  applySearch = debounce((value) => {

    this.setState({
      criteria: value.toLowerCase()
    });
  }, 250);

  handleClick = (simulator) => {
    this.props.history.push(`/simulators/${simulator.id}`);
  }

  toggleForm = () => {

    this.setState({
      isFormOpen: !this.state.isFormOpen
    });
  }

  handleFormSuccess = (simulator) => {

    const { simulators } = this.state;

    this.setState({
      simulators: [
        ...simulators,
        simulator
      ]
    }, this.toggleForm);
  }

  render() {

    const { loading, search, criteria, isFormOpen } = this.state;

    const all = this.state.simulators;
    const visible = Arrays.filterByCriteria(all, criteria, (simulator) => {

      return [
        `#${simulator.id}`,
        simulator.customer?.company,
        simulator.app_version,
        simulator.ios_version,
        Conditioning.parse(simulator.conditioning)?.name,
        simulator.active_warranty ? moment(simulator.active_warranty.end, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined,
        simulator.device?.model,
        simulator.device?.serial
      ]
    })

    return (
      <GenericPage>

        <Flex vertical space={16}>

          <Flex spaceBetween space={8}>

            <Search placeholder="Rechercher" allowClear onChange={this.handleSearch} value={search} />

            <Button type="primary" onClick={this.toggleForm}>
              Nouveau
            </Button>

          </Flex>

          <Table bordered
            loading={loading}
            dataSource={visible}
            rowKey={simulator => simulator.id}
            onRow={(simulator, rowIndex) => {
              return {
                onClick: () => this.handleClick(simulator)
              };
            }}
            pagination={{ position: ['bottomCenter'] }}
            expandable={{
              columnTitle: <TagOutlined />,
              expandedRowRender: (simulator) => (
                <Tag color="cyan" icon={<TagOutlined />}>
                  {simulator.tag}
                </Tag>
              ),
              rowExpandable: (simulator) => simulator.tag,
            }}
          >

            {/* ID */}
            {this.column_ID(all, visible)}

            {/* Customer */}
            {this.column_customer(all, visible)}

            {/* Version */}
            <ColumnGroup title="Versions">

              {/* iOS version */}
              {this.column_appVersion(all, visible)}

              {/* iOS version */}
              {this.column_iOSVersion(all, visible)}

            </ColumnGroup>

            {/* Device */}
            <ColumnGroup title={
              <span>
                <TabletFilled /> iPad
              </span>
            }>

              {/* Device model */}
              {this.column_deviceModel(all, visible)}

              {/* Device serial */}
              {this.column_deviceSerial(all, visible)}

            </ColumnGroup>

            {/* Configuration */}
            {this.column_configuration(all, visible)}

            {/* Conditioning */}
            {this.column_conditioning(all, visible)}

            {/* Warranty */}
            {this.column_warranty(all, visible)}

          </Table>

          {/* Modal */}
          <Drawer title="Nouveau simulateur" placement="right" visible={isFormOpen} onClose={this.toggleForm} maskClosable={false} >

            {isFormOpen &&
              <SimulatorForm onSuccess={this.handleFormSuccess} makeRequest={(_, data) => API.post(`/simulators`, data)} />
            }

          </Drawer>

        </Flex>

      </GenericPage>
    );
  }

  // Columns

  column_ID = (all, visible) => {

    let sorter = (a, b) => Compare.number(a.id, b.id);

    return (
      <Column
        title="#"
        dataIndex="id"
        key="id"
        sorter={sorter}
        fixed
        render={(id) => {
          return (
            <Avatar>{id}</Avatar>
          )
        }}
      />
    )
  }

  column_customer = (all, visible) => {

    let allCustomers = visible
      .map((simulator) => simulator.customer?.company)
      .filter(Boolean)
      .unique()

    let filters = [
      {
        text: 'Aucun',
        value: -1
      },
      ...allCustomers.map((customer) => {
        return {
          text: customer,
          value: customer
        }
      })
    ]

    let onFilter = (filter, simulator) => {

      const { customer } = simulator;
      return customer ? customer.company === filter : filter === -1;
    };

    let sorter = (a, b) => Compare.string(a.company, b.compay);

    return (
      <Column
        title="Client"
        dataIndex="customer"
        key="customer"
        filters={filters}
        onFilter={onFilter}
        sorter={sorter}
        render={(customer, simulator) => {
          return (
            <Flex vertical alignLeft>

              {/* Customer */}
              {customer?.company ?? <Empty />}

            </Flex>
          )
        }}
      />
    )
  }

  column_appVersion = (all, visible) => {

    /** All uniques versions */
    let versions = visible.map((simulator) => simulator.app_version)
      .unique()
      .filter(Boolean)
      .sort(Compare.versionInverse)

    let filters = [
      {
        text: 'Aucune',
        value: -1
      },
      ...versions.map((version) => {

        return {
          text: version,
          value: version
        }
      })
    ]

    let onFilter = (filter, simulator) => {

      const { app_version } = simulator;
      return app_version ? app_version === filter : filter === -1;
    };

    let sorter = (a, b) => Compare.version(a.app_version, b.app_version);

    return (
      <Column
        title="Application"
        key="app_version"
        dataIndex="app_version"
        filters={filters}
        onFilter={onFilter}
        sorter={sorter}
        render={(_, simulator) => {

          if(Typeof.string(simulator.app_version)) {

            return (
              <Space>
                <TabletFilled />
                {simulator.app_version}
              </Space>
            )
          }

          return <Empty />
        }}
      />
    )
  }

  column_iOSVersion = (all, visible) => {

    /** All uniques versions */
    let versions = visible.map((simulator) => simulator.ios_version)
      .unique()
      .filter(Boolean)
      .sort(Compare.versionInverse)

    let filters = [
      {
        text: 'Aucune',
        value: -1
      },
      ...versions.map((version) => {

        return {
          text: version,
          value: version
        }
      })
    ]

    let onFilter = (filter, simulator) => {

      const { ios_version } = simulator;
      return ios_version ? ios_version === filter : filter === -1;
    };

    let sorter = (a, b) => Compare.version(a.ios_version, b.ios_version);

    return (
      <Column
        title="iOS"
        key="ios_version"
        dataIndex="ios_version"
        filters={filters}
        onFilter={onFilter}
        sorter={sorter}
        render={(_, simulator) => {

          if (Typeof.string(simulator.ios_version)) {

            return (
              <Space>
                <TabletFilled />
                {simulator.ios_version}
              </Space>
            )
          }

          return <Empty />
        }}
      />
    )
  }

  column_deviceModel = (all, visible) => {

    /** All uniques models */
    let models = visible.map(simulator => simulator.device?.model)
      .unique()
      .filter(Boolean)
      .sort(Compare.versionInverse);

    let filters = [
      {
        text: 'Aucun',
        value: -1
      },
      ...models.map((model) => {

        return {
          text: model,
          value: model
        }
      })
    ]
    // Filter callback
    let onFilter = (filter, simulator) => {

      const { model } = simulator.device;
      return model ? model === filter : filter === -1;
    }

    // Sort function
    let sorter = (a, b) => Compare.string(a.device?.model, b.device?.model)

    return (
      <Column
        title="Modèle"
        dataIndex={["device", "model"]}
        key="device.model"
        filters={filters} onFilter={onFilter}
        sorter={sorter}
        render={(model) => {
          return model ?? <Empty />
        }}
      />
    )
  }

  column_deviceSerial = (all, visible) => {

    // Sort function
    let sorter = (a, b) => Compare.string(a.device?.serial, b.device?.serial)

    return (
      <Column
        title="N° de série"
        dataIndex={["device", "serial"]}
        key="device.serial"
        sorter={sorter}
        render={(_, simulator ) => {
          return simulator.device?.serial ?? <Empty />
        }}
      />
    )
  }

  column_configuration = (all, visible) => {

    // Filters
    let filters = [
      {
        text: 'Sans configuration',
        value: 'none'
      }
    ]

    let kindsWithoutCategory = [];
    let kindsByCategory = {};

    EquipmentKind.values().forEach(kind => {

      if(kind.category) {

        let kinds = kindsByCategory[kind.category] ?? [];
        kinds.push(kind);
        kindsByCategory[kind.category] = kinds;

      } else {
        kindsWithoutCategory.push(kind);
      }

    });

    // Kinds by category
    if(Object.keys(kindsByCategory).length > 0) {

      for (const category in kindsByCategory) {

        const kinds = kindsByCategory[category];

        filters = [...filters, {
          text: category,
          children: kinds.map((kind) => {
            return {
              text: kind.name,
              value: kind.id
            }
          })
        }]
      }
    }

    // Kinds without category
    if(kindsWithoutCategory.length > 0) {

      filters = [...filters,
        kindsWithoutCategory.map((kind) => {
          return {
              text: kind.name,
              value: kind.id
          }
        })
      ]
    }

    // Filter callback
    let onFilter = (filter, simulator) => {

      switch(filter)
      {
        case 'none':
          return Typeof.undefined(simulator.configuration);

        default:
          return Typeof.string(simulator.configuration) && simulator.configuration.includes(filter);
      }
    }

    // Sort function
    let sorter = (a, b) => Compare.string(a.configuration, b.configuration)

    return (
      <Column
        title="Configuration"
        dataIndex="configuration"
        key="configuration"
        filters={filters}
        filterMode="tree"
        onFilter={onFilter}
        sorter={sorter}
        render={(configuration) => {

          if(Typeof.string(configuration)) {

            return (
              <Flex wrap space="8px">
                {configuration.split(',').map((a) => {
                  return (
                    <Tag key={a} style={{margin: 0}} color="blue">
                      {a.trim()}
                    </Tag>
                  )
                }) }
              </Flex>
            )
          }

          return (
            <Empty />
          )
        }}
      />
    )
  }

  column_conditioning = (all, visible) => {

    // Filters
    let filters = Conditioning.values()
      .map((conditioning) => {
        return {
          text: conditioning.name,
          value: conditioning.id
        }
      });

    // Filter callback
    let onFilter = (filter, simulator) => {
      return simulator.conditioning === filter;
    }

    // Sort function
    let sorter = (a, b) => Compare.string(a.conditioning, b.conditioning)

    return (
      <Column
        title="Conditionnement"
        dataIndex="conditioning"
        key="conditioning"
        filters={filters} onFilter={onFilter}
        sorter={sorter}
        render={(conditioning) => {

          let name = Conditioning.parse(conditioning)?.name;

          if(Typeof.string(name)) {

            return (
              <Tag>
                {name}
              </Tag>
            )
          }

          return (
            <Unknown help="Valeur inconnue" color="red" placement="topRight">
              {conditioning}
            </Unknown>
          )
        }}
      />
    )
  }

  column_warranty = (all, visible) => {

    let durations = visible.map((simulator) => simulator.active_warranty?.duration)
      .unique()
      .filter(Boolean)
      .sort(Compare.numberInverse)

    let status = WarrantyStatus.values().map((status) => {
      return {
        text: <Pin color={status.color}>{status.name}</Pin>,
        value: status.id
      }
    })

    // Filters
    let filters = [
      {
        text: 'Sans garantie',
        value: 'none'
      },
      {
        text: 'Statut',
        children: status
      }
    ]

    if(durations.length > 0) {
      filters = [
        ...filters,
        {
          text: 'Durée',
          children: durations.map((duration) => {
            return { text: `${duration} mois`, value: duration }
          })
        }
      ]
    }

    let onFilter = (filter, simulator) => {

      const warranty = simulator.active_warranty

      // Filter by duration
      if (Typeof.number(filter)) {
        return warranty?.duration === filter

      } else { // Filter by state

        switch (filter) {

          case 'none':
            return !Typeof.object(warranty)

          default:
            return warranty?.status === filter;
        }
      }
    }

    let sorter = (a, b) => Compare.number(a.active_warranty?.remaining, b.active_warranty?.remaining)

    return (
      <Column
        title="Garantie"
        key="warranty"
        filters={filters}
        filterMode="tree"
        onFilter={onFilter}
        sorter={sorter}
        render={(_, simulator) => {

          const warranty = simulator.active_warranty;

          return (
            <WarrantySummary warranty={warranty} />
          )
        }}
      />
    )
  }
}

export default SimulatorsPage;
