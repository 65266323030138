import Enum from './_Enum';

import { Link } from 'react-router-dom';

import {
  HistoryOutlined,
  LinkOutlined,
  CloudSyncOutlined,
  SyncOutlined
} from '@ant-design/icons';

import { Tag, Space } from 'antd';

import { Flex, Pin, Unknown } from 'app/components';

import { BoardStatus, Channel } from 'app/enums';

import { API } from 'app/services';

let Universe = {

  ASSOCIATE: {
    type: 'ASSOCIATE',
    name: 'Association',
    message: (payload) => {

      const { serial } = payload;

      return (
        <Space>
          La carte a été associé à l'équipement
          <Link to={`/equipments/${serial}`}>
            <Tag>{serial}</Tag>
          </Link>
        </Space>
      )
    },
    icon: <LinkOutlined />
  },

  DISSOCIATE: {
    type: 'DISSOCIATE',
    name: 'Dissociation',
    message: (payload) => {

      const { serial } = payload;

      return (
        <Space>
          La carte a été dissocié de l'équipement
          <Link to={`/equipments/${serial}`}>
            <Tag>{serial}</Tag>
          </Link>
        </Space>
      )
    },
    icon: <LinkOutlined />
  },

  STATUS_CHANGE: {
    type: 'STATUS_CHANGE',
    name: 'Changement de statut',
    message: (payload) => {

      const status = BoardStatus.parse(payload.status);

      return (
        <Space>
          Le statut de la carte a modifié en

          {/* Status */}
          {status ? (
            <Pin color={status.color}>
              {status.name}
            </Pin>
          ) : (
            <Unknown color="red" help="oœuf ">
              {payload.status}
            </Unknown>
          )}
        </Space>

      )
    },
    icon: <HistoryOutlined />
  },

  SYNC: {
    type: 'SYNC',
    name: 'Synchronisation',
    message: (payload) => "La carte a été synchronisé",
    icon: <CloudSyncOutlined />
  },

  FIRMWARE_UPDATE: {
    type: 'FIRMWARE_UPDATE',
    name: 'Mise à jour logiciel',
    message: (payload) => {

      const { version } = payload;
      const channel = Channel.parse(payload.channel);

      return (
        <Space>
          Le micrologiciel a été mis à jour en version

          {channel ? (
            <Link to={`/firmwares${API.queryString({ version: version, channel: channel.id})}`}>
              <Pin color={channel.color}>
                {version}
              </Pin>
            </Link>
          ) : (
            <Unknown help="Canal inconnu" color="red">
              {version}@{payload.channel}
            </Unknown>
          )}
        </Space>
      )
    },
    icon: <SyncOutlined />
  }

};

export default Enum(Universe, undefined, (type, values) => {
  return values.find((value) => value.type === type);
});
