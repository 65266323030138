import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom';

import { Pin, Unknown } from 'app/components';

import { Channel } from 'app/enums';

import { API } from 'app/services';

class FirmwareVersion extends React.Component {

  render() {

    const { version, channel } = this.props;
    const _channel = Channel.parse(channel);

    return (
      <>
        {_channel ? (
          <Link to={`/firmwares${API.queryString({ version: version, channel: channel })}`}>
            <Pin color={_channel.color}>
              {version}
            </Pin>
          </Link>
        ) : (
          <Unknown help="Canal inconnu" color="red">
            {version}@{channel}
          </Unknown>
        )}
      </>
    );
  }
}

export default FirmwareVersion;
