import React from 'react'
import PropTypes from 'prop-types'

import { Button, Form, InputNumber, Input, Select } from 'antd';
import { Col, Row } from 'antd';

import { Pin } from 'app/components';

import { Channel, Conditioning } from 'app/enums'

import { Typeof, Notifications, Validation, Constants } from 'app/utils';

class SimulatorForm extends React.Component {

  constructor(props) {

    super(props);

    const { source } = props;

    let fields = [
      { name: ['id'], value: source?.id },
      { name: ['tag'], value: source?.tag },
      { name: ['channel'], value: source?.channel ?? Channel.RELEASE.id },
      { name: ['conditioning'], value: source?.conditioning ?? Conditioning.CASE.id }
    ]

    this.state = {
      pending: false,
      fields: fields
    };
  }

  handleSubmit = async (data) => {

    const { source, makeRequest } = this.props;

    this.setState({
      pending: true
    });

    let newState = {
      pending: false
    };

    var onComplete;

    try {

      let response = await makeRequest(source, data);

      //Notify success
      onComplete = () => {
        this.props.onSuccess(response.data)
      }

    } catch (error) {

      try {

        // Validation
        newState.fields = Validation.parseValidationsErrors(this.state.fields, error);

      } catch (error) {

        // Unhandled error
        Notifications.error(
          "Oops, quelque chose s'est mal passé...",
          "Une erreur est survenue lors de la mise à jour des informations du simulateur",
          error.message
        )
      }

    } finally {
      this.setState(newState, onComplete);
    }
  }

  handleFieldsChange = (_, fields) => {

    this.setState({
      fields: fields
    });
  }

  render() {

    const { fields, pending } = this.state;

    let isEditing = Typeof.object(this.props.source);

    return (
      <Form
        name="simulator-form"
        fields={fields}
        layout="vertical"
        onFieldsChange={this.handleFieldsChange}
        disabled={pending}
        onFinish={this.handleSubmit}
      >

        {/* ID */}
        {!isEditing &&
          <Form.Item name="id" label="Identifiant de produit" required>
            <InputNumber min={1} max={Constants.MAX_SIMULATORS} addonBefore="#" style={{ width: '100%' }} />
          </Form.Item>
        }

        <Form.Item name="tag" label="Étiquette">
          <Input style={{ width: '100%' }} />
        </Form.Item>

        <Row gutter={10}>

          <Col span={12}>

            {/* Channel */}
            <Form.Item name="channel" label="Canal de mise à jour" required>
              <Select>

                {Channel.values().map((channel) => {

                  return (
                    <Select.Option key={channel.id} value={channel.id}>
                      <Pin color={channel.color}>
                        {channel.name}
                      </Pin>
                    </Select.Option>
                  )
                })}

              </Select>
            </Form.Item>

          </Col>

          <Col span={12}>

            {/* Conditioning */}
            <Form.Item name="conditioning" label="Conditionnement" required>
              <Select>

                {Conditioning.values().map((conditioning) => {

                  return (
                    <Select.Option key={conditioning.id} value={conditioning.id}>
                      {conditioning.name}
                    </Select.Option>
                  )
                })}

              </Select>
            </Form.Item>

          </Col>

        </Row>

        <Button form="simulator-form" type="primary" htmlType="submit">
          Valider
        </Button>

      </Form>
    );
  }
}

export default SimulatorForm;
