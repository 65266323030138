import React from 'react'
import PropTypes from 'prop-types'

import { Modal, Button, Result } from 'antd';

class NotFoundPage extends React.Component {

  handleClick = () => {
    this.props.history.goBack();
  }

  render() {
    return (
      <Modal visible closable={false} centered footer={false}>
        <Result
          status="404"
          title="404"
          subTitle="La page demandée est introuvable"
          extra={
            <Button type="primary" onClick={this.handleClick}>Retour</Button>
          }
        />
      </Modal>
    );
  }
}

export default NotFoundPage;
