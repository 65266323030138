const MESSAGES = {

  'value:required': 'Valeur requise',

  'value:invalid': 'Valeur invalide',

  'value.format:invalid': 'Format invalide',

  'value:used': 'Valeur déjà utilisée',

  'value:unvailable': 'Valeur indisponible'
}

class Validation {

  static parseValidationsErrors(fields, error) {

    if (error.response && error.response.status === 400) {

      // Parse fields errors
      let validationErrors = JSON.parse(error.response.data.message);

      return fields.map((field) => {

        const name = field.name[0];
        let errors = validationErrors.filter(error => error.field == name).map(value => MESSAGES[value.error] ?? value.error)

        return {
          ...field,
          errors: errors
        }
      })
    }

    throw error;
  }
}

export default Validation;
