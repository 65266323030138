import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom';

import { GenericPage } from 'app/pages';

import {
  LinkOutlined,
  FieldTimeOutlined
} from '@ant-design/icons';

import { Button, Skeleton, PageHeader, Tabs, Drawer, Avatar, Badge, Popconfirm, Space, Tooltip, Select, Alert } from 'antd';

import { Flex, Discrete } from 'app/components';

import {
  SimulatorForm,
  ChoiceForm
} from 'app/forms';

import {
  InformationsTab,
  EquipmentsTab,
  WarrantiesTab,
  ContactsTab,
  TimelineTab
} from './tabs';

import { API } from 'app/services';
import { BreadcrumbHelper, Typeof, Notifications, Arrays } from 'app/utils';

class SimulatorPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      fetching: true,
      isEditing: false,
      isAssociating: false
    }
  }

  async componentDidMount() {

    BreadcrumbHelper.shared
      .append("Simulateurs", '/simulators')
      .flush();

    try {

      const id = this.props.match.params['simulator'];

      // Fetch simulator
      let response = await API.get(`/simulators/${id}`)

      let simulator = response.data

      // Update breadcrumb
      if(Typeof.object(simulator.customer)) {

        const { customer } = simulator;

        BreadcrumbHelper.shared
          .append(customer.company, `/customers/${customer.id}`);
      }

      BreadcrumbHelper.shared
        .append(`#${id}`)
        .flush();

      this.setState({
        fetching: false,
        simulator: simulator
      });

    } catch(error) {

      // Show error
      Notifications.error(
        "Oops, quelque chose s'est mal passé...",
        "Une erreur est survenue lors de la récupération des informations du simulateur",
        error.message
      )

      this.props.history.push('/simulators')
    }
  }

  onSimulatorUpdate = (simulator, onComplete) => {

    this.setState({
      simulator: simulator
    }, onComplete);
  }

  handleTabChange = (key) => {

    window.location.hash = key;
    this.forceUpdate();
  }

  toggleAssociating = () => {

    this.setState({
      isAssociating: !this.state.isAssociating
    });
  }

  handleAssociate = (choices, choice) => {

    const { simulator } = this.state;
    const customer = choices.find((a) => a.id === choice);

    this.setState({
      simulator: {
        ...simulator,
        customer: customer
      }
    }, this.toggleAssociating)
  }

  handleDissociate = async () => {

    const { simulator } = this.state;
    const { customer } = simulator;

    try {

      await API.delete(`/customers/${customer.id}/simulators/${simulator.id}`);

      this.onSimulatorUpdate({
        ...simulator,
        customer: undefined
      })

    } catch {

    }
  }

  toggleForm = () => {

    this.setState({
      isEditing: !this.state.isEditing
    });
  }

  handleFormSuccess = (simulator) => {

    this.setState({
      simulator: simulator
    }, this.toggleForm);
  }

  fetchCustomers = async () => {

    let response = await API.get('/customers');
    return response.data;
  }

  renderSimulator() {

    let currentTab = 'general';
    let hash = window.location.hash?.substring(1);

    if (hash) {

      switch (hash) {

        case 'equipments':
        case 'history':
        case 'timeline':
        case 'warranties':
        case 'contacts':
          currentTab = hash;

        default:
          window.location.hash = currentTab;
          break
      }
    }

    const { simulator, isEditing, isAssociating } = this.state;

    const { customer } = simulator;

    return (

      <PageHeader title={
        <>
          <Avatar>{simulator.id}</Avatar>
          Simulateur
        </>
      } subTitle={simulator.configuration} ghost={false} extra={
        <>
          {customer ? (
            <>
              <Link to={`/customers/${customer.id}`}>
                <Flex vertical alignRight>
                  {customer.company}

                  <Discrete>
                    {`${customer.address}, ${customer.zip_code} ${customer.city}`}
                  </Discrete>
                </Flex>
              </Link>

              {/* Dissociate */}
              <Popconfirm
                overlayStyle={{ maxWidth: '250px' }}
                title={`Êtes-vous sûr de vouloir dissocier ce simulateur du client ${customer.company} ?`}
                onConfirm={this.handleDissociate}
                okButtonProps={{ type: 'danger' }}
                okText="Oui"
                cancelText="Annuler"
              >
                <Tooltip title="Dissocier">
                  <Button type="danger" shape="circle">
                    <LinkOutlined />
                  </Button>
                </Tooltip>
              </Popconfirm>
            </>
          ) : (
            <>
              {isAssociating ? (

                <ChoiceForm
                  choices={this.fetchCustomers()}
                  placeholder="Sélectionner un client"
                  onSuccess={this.handleAssociate}
                  onCancel={this.toggleAssociating}
                  renderOption={(customer, i) => {
                    return (
                      <Select.Option key={i} value={customer.id}>
                        <Flex vertical>

                          {/* Company */}
                          {customer.company}

                          <Discrete>
                            <span style={{ whiteSpace: 'normal' }}>
                              {`${customer.address}, ${customer.zip_code} ${customer.city}`}
                            </span>
                          </Discrete>
                        </Flex>
                      </Select.Option>
                    )
                  }}
                  filterOption={(input, choice, choices) => {

                    const customer = choices.find((a) => a.id === choice);

                    let match = Arrays.filterByCriteria([ customer ], input, (customer) => {

                      return [
                        customer.company,
                        customer.address,
                        customer.zip_code,
                        customer.city
                      ]
                    });

                    return match.length > 0;
                  }}
                  makeRequest={(customer) => API.put(`/customers/${customer}/simulators/${simulator.id}`)}
                />

              ) : (
                <>
                  <Tooltip title="Associer">
                    <Button type="primary" shape="circle" onClick={this.toggleAssociating}>
                      <LinkOutlined />
                    </Button>
                  </Tooltip>
                </>
              )}
            </>
          )}
        </>
      }>

        {/* No customer warning */}
        {Typeof.undefined(customer) &&
          <Alert
            message="Attention"
            description={
              <span>
                Ce simulateur n'est pas associé à un <Link to="/customers">client</Link>
              </span>
            }
            type="warning"
            closable
            showIcon
          />
        }

        <Tabs activeKey={currentTab} onChange={this.handleTabChange} destroyInactiveTabPane>

          {/* Simulators */}
          <Tabs.TabPane tab="Général" key="general">
            <InformationsTab simulator={simulator} onSimulatorUpdate={this.onSimulatorUpdate} />
          </Tabs.TabPane>

          {/* Equipments */}
          <Tabs.TabPane tab={
            <Space>
              Équipements
              <Badge count={simulator.equipments.length} />
            </Space>
          } key="equipments">
            <EquipmentsTab simulator={simulator} onSimulatorUpdate={this.onSimulatorUpdate} />
          </Tabs.TabPane>

          {/* Timeline */}
          <Tabs.TabPane tab={
            <span>
              Chronologie <FieldTimeOutlined className="inline" />
            </span>
          } key="timeline" >
            <TimelineTab simulator={simulator} />
          </Tabs.TabPane>

          {/* Warranty */}
          <Tabs.TabPane tab="Garantie(s)" key="warranties">
            <WarrantiesTab simulator={simulator} onSimulatorUpdate={this.onSimulatorUpdate} />
          </Tabs.TabPane>

          {/* Contacts */}
          <Tabs.TabPane tab="Contacts" key="contacts">
            <ContactsTab simulator={simulator} />
          </Tabs.TabPane>

        </Tabs>

        {/* Edit form */}
        <Drawer title="Modification du simulateur" placement="right" visible={isEditing} onClose={this.toggleForm} maskClosable={false} >

          {isEditing &&
            <SimulatorForm source={simulator} onSuccess={this.handleFormSuccess} makeRequest={(simulator, data) => API.put(`/simulators/${simulator.id}`, data)} />
          }

        </Drawer>

      </PageHeader>
    )
  }

  render() {

    const { fetching } = this.state;

    return (
      <GenericPage>

        {fetching ? (

          <PageHeader title="Chargement...">
            <Skeleton />
          </PageHeader>

        ) : (

          this.renderSimulator()
        )}

      </GenericPage>
    );
  }
}

export default SimulatorPage;
