/**
 * @param {any[]} universe
 * @param {any?} defaultValue
 * @param {callback<id, values>} parse
 */
const seal = (universe, defaultValue, parse) => {

  const allValues = Object.values(universe);

  return Object.seal({

    ...universe,

    /**
     * Gets all value.
     * @returns all value
     */
    values: () => allValues,

    /**
     * Gets the default value.
     */
    default: defaultValue,

    /**
     * Returns a value from its ID.
     * @param {any} id
     * @returns a value otherwise undefined
     */
    parse: (id) => {
      return parse(id, allValues);
    }
  })
};

export default seal;
